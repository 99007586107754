import React,{ useEffect, useState} from 'react';
import axios from 'axios';

import moni from 'F:/projet/first/src/components/image/moni.png';
import log from 'F:/projet/first/src/components/image/log.png';
import Loader from "../components/loader";
import Footer from '../components/footer';
import { Link , useParams,NavLink, useNavigate,  useLocation} from "react-router-dom";
import secre from 'F:/projet/first/src/components/image/images.png'
import Cookies from 'js-cookie';
import { sidedataDir } from 'F:/projet/first/src/components/sidedataDir'
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";
import logo from 'F:/projet/first/src/components/image/logo4.jpg'
import { FaPhoneAlt } from 'react-icons/fa';
import Modal from '../components/popup';
import ErrorModal from '../components/errorpopup';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
export default function CandidatDir(){
    const [titre,setTitre]= useState(Cookies.get('titre'))  
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [loader, setLoader]= useState(true)
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [sidebar, setSidebar]= useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const [lu,setLu] = useState(Cookies.get('token'))
    const [tok, setTok]= useState('')
    const [error, setError] = useState(null);

    const [viewLoader, setViewLoader] = useState(false)
    const [ce,setCe]= useState(Cookies.get('autoa'))

    const [select1,setSelect1]= useState(Cookies.get('select1'))
    const [select2,setSelect2]= useState(Cookies.get('select2'))
    const [old, setOld]= useState('')
    const [recup, setRecup]= useState('')
    const [new1, setNew1]= useState('')
    const [new2, setNew2]= useState('')
    const [info1, setInfo1]= useState('')
    const [info2, setInfo2]= useState('')
    const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
    const mettreAJourLargeurEcran = () => {
        setLargeurEcran(window.innerWidth);
    
      };

      if(tok!= ''){
        if(tok!= lu){
            Cookies.remove('token')
            Cookies.remove('autoa')
            Cookies.remove('titre')
            Cookies.remove('gestauto')
            window.location.reload();
            navigate('/')

        }
    }

    var navigate= useNavigate()
    useEffect(()=>{
         if(!Cookies.get('token')){
            navigate('/')
         }
    }, [])


    
    
    const logout = () =>{
        Cookies.remove('token')
        Cookies.remove('autoa')
        Cookies.remove('titre')
        Cookies.remove('gestauto')
        Cookies.remove('default')
        Cookies.remove('default2')
        Cookies.remove('defaultCode')
        Cookies.remove('defaultCode2')
        Cookies.remove('select1')
        Cookies.remove('select2')
        window.location.reload();
       navigate('/')
    }
    useEffect(()=>{
        if(!Cookies.get('token')){
           navigate('/')
        }
   }, [])





useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);
// Recuperer token
useEffect(()=>{
    

axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/setToken', { gestauto, titre},{ headers})
    .then(res => 
        {setTok(res.data[0].token)
        

        })
    .catch(err => console.log(err));
}, [])
//nav en haut

    const[profil, setProfil]= useState(false)

    const showProfil = () => setProfil(!profil)

    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])

   





    const [annexe, setAnnexe]= useState('')
    const [lea,setLea]=useState([0])
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  var month2= date.getMonth()+1
  var day2= date.getDate()
  const days= date.getDate()
  var datee= new Date(years,month-2,days)
  var autre2= datee.toISOString().split('T')[0] 
  var autre= date.toISOString().split('T')[0]

    const[value,setValue]= useState('')
    const [idAnnexe,setIdAnnexe]= useState('')
    const[controleAnnexe,setControleAnnexe]= useState('')
    const [date1, setDate1]= useState('')
    const [date2, setDate2]= useState('')
    const [loga, setLoga]= useState('')
    
    const [logaId, setLogaID]= useState(0)
    const [valeurLogaID, setValeurLogaID]= useState('')
   
   
    const [ville, setVille] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir?id='+ce+'' , { headers})
        .then(res =>{ setVille(res.data)
        setLoga(res.data[0].ville)
        setLogaID(res.data[0].id_annexe)
        setValeurLogaID(res.data[0].id_ecole)
        setAnnexe(res.data[0].ville)
        })
        .catch(err => console.log(err));
    }, [])

    const [id_annexe]= ville.map((data)=>(data.id_annexe));
    
     const [query, setQuery]= useState(""); 
  

         useEffect(()=>{
       
           
                setDate1(autre2)
                setDate2(autre)
          
        }, [])
        

         
         const [candidat, setCandidat] = useState([])

  const handleDelete = async(id) => {
        try {
         await axios.delete('https://gestauto-56bfcbe4b876.herokuapp.com/depense/'+id, { headers})
         window.location.reload()
        }catch(err) {
         console.log(err);
        }
      }

         function handleSubmit(event){
            setViewLoader(true)
            event.preventDefault();
            
            const [id_ecole]= ville.map((data)=>(data.id_ecole));

            
           
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candidatDir', {annexe,id_annexe,id_ecole}, { headers})
            .then(res => {
                
           
                const a= res.data[0].id_ecole
              
                setLea(res.data)
           
                setControleAnnexe(a);
                /*axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/get' )
                .then(res => setCandidat(res.data))*/   
                if(value==''){                 
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/info', {a,month,years}, { headers})
                .then(res =>{ 
                    
                    setCandidat(res.data)   
                    setViewLoader(false)          
                }
                   

                ).catch(err =>{
                     console.log(err)
                    setError(err)
                    setViewLoader(false)
                    
                    });
                
            }else if(value=='Enregistré cette année'){
            
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/toutInit',{years,a}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                    setViewLoader(false)
                    
                    });
                  if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoThisYearsInit',{years,a}, { headers})
                .then(res =>{ setCandidat(res.data)
                setViewLoader(false)
                })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                    setViewLoader(false)
                    });
            }
            }else if(value=='Enregistré ce mois'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/moisInit',{month,years,a}, { headers})
                .then(res =>{
                     setCandidat(res.data)
                    setViewLoader(false)
                    
                    })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                if(o==0){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoThisInit',{month,years,a}, { headers})
                    .then(res => {
                        setCandidat(res.data)
                        setViewLoader(false)
                    })
                    .catch(err =>{
                        console.log(err)
                        setError(err)
                       setViewLoader(false)
                       });
                }
                }else if(value=='Tous les Candidats'){
    
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/candInit',{a}, { headers})
                    .then(res => {
                        setCandidat(res.data)
                        setViewLoader(false)
                    })
                    .catch(err =>{
                        console.log(err)
                        setError(err)
                       setViewLoader(false)
                       });
                    if(o==0){
                        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoInit',{a}, { headers})
                    .then(res => {
                        setCandidat(res.data)
                        setViewLoader(false)
                    })
                    .catch(err =>{
                        console.log(err)
                        setError(err)
                       setViewLoader(false)
                       });
                    }
                }
            else if(value=='Qui ont retiré leurs permis'){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/permisInit',{a}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                if(o==0){
                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaInfoPermisInit',{a}, { headers})
                .then(res => {
                    setCandidat(res.data)
                    setViewLoader(false)
                })
                .catch(err =>{
                    console.log(err)
                    setError(err)
                   setViewLoader(false)
                   });
                }
            }
// pour le seelect des options



  
//fin
            
               
        
                
                
                    
                
            
            
            }).catch(err =>{
                console.log(err)
               
               
               });

                

            
            
        
        }
        var cont=0
        
        
        const  le = lea.map((data)=>(data.id_ecole))

        
           
    
        var o=le
        const {id}= useParams();
        if (o!=0){
            o=le
        }else{
            
            o=id
        }

if(value==''){
    if(select1=== undefined || select2===undefined){
        if(logaId!='' && o==0){
            
        
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
            .then(res => {
                
       
                const a= res.data[0].id_ecole
              
                
                
                setControleAnnexe(a);
                setLogaID('')
                /*axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/get' )
                .then(res => setCandidat(res.data))*/                    
                /*
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/info', {a,month,years}, { headers})
                .then(res =>{ 
                   
                    setCandidat(res.data) 
                    setLoader(false)            
                }
                   
    
                ).catch(err =>{
                     console.log(err)
                    setError(err)
                    setLoader(false)
                    
                    });
               */

                    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/CandParDef', {a,date1,date2}, { headers})
                    .then(res =>{ 
                       
                        setCandidat(res.data) 
                        setLoader(false)            
                    }
                        
                    ).catch(err =>{
                         console.log(err)
                        setError(err)
                        setLoader(false)
                        
                        });
                      
            
            }).catch(err =>{ console.log(err)
                setError(err)
                setLoader(false)
            });
            
    
        
        }
    }else if(select1 || select2){
        if(logaId!='' && o==0){
            
        
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defa', {loga,logaId}, { headers})
            .then(res => {
                
       
                const o= res.data[0].id_ecole
              
                
                
                setControleAnnexe(o);
                setLogaID('')
                /*axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/get' )
                .then(res => setCandidat(res.data))*/   
                                 
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoir', {o,select1,select2}, { headers})
                .then(res =>{ 
                   
                    setCandidat(res.data) 
                    setLoader(false)            
                }
                    
                ).catch(err =>{
                     console.log(err)
                    setError(err)
                    setLoader(false)
                    
                    });

            }).catch(err =>{ console.log(err)
                setError(err)
                setLoader(false)
            });
            
    
        
        }
    }
    }



        useEffect(()=>{
            if(o!=0){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/idAnnexe', {o}, { headers})
        .then(res =>{ 
            
            setIdAnnexe(res.data[0].ville)             
        }
           

        ).catch(err => console.log(err));
    }
    }, [])
        var b
       
     
      if(idAnnexe!=''){
        b= idAnnexe
      }  else{
        b= loga
      }
     
        
         
        useEffect(()=>{
            if( select1 === undefined || select2=== undefined){
            
            if(value==''){
            if(o!=0){
                /*
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tic', {o,month,years,le}, { headers})
            .then(res =>{ 
                console.log('ah paapa')
                setCandidat(res.data)  
                setLoader(false)           
            }
               

            ).catch(err =>{
                 console.log(err)
                setError(err)
                setLoader((false))
                });
                */


                let date1=autre2
                let date2=autre
                const a= o;


                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/CandParDef', {a,date1,date2}, { headers})
                .then(res =>{ 
                   
                    setCandidat(res.data) 
                    setLoader(false)            
                }
                    
                ).catch(err =>{
                     console.log(err)
                    setError(err)
                    setLoader(false)
                    
                    });
                  
    


        }
    }
    }else if(select1 || select2) {
        setDate1(select1)
        setDate2(select2)
        if(value==''){
         
     
       if(o!=0){
        console.log('ah maamaa')
       axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vVoir', {select1,select2,o}, { headers})
       .then(res => {
          setCandidat(res.data)
         setLoader(false)
           
           
       }).catch(err =>{
           console.log(err)
           setError(err)
          setLoader(false)
          });
        }
      
    }
    }
        }, [])

           


      


        function handlePropose(event){
            Cookies.set('select1',date1)
            Cookies.set('select2',date2)
            setViewLoader(true)
            event.preventDefault();
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/personCandidatDir', {date1,date2,o,le}, { headers})
            .then(res => {
               setCandidat(res.data)
              setViewLoader(false)
                
                
            }).catch(err =>{
                console.log(err)
                setError(err)
               setViewLoader(false)
               });
            if(o==0){
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/defaFiltre', {date1,date2,valeurLogaID}, { headers})
            .then(res => {
               setCandidat(res.data)
               setViewLoader(false)
                          
            }).catch(err =>{
                console.log(err)
                setError(err)
               setViewLoader(false)
               });
            }
        }
         var ecrit
   
//ici nav var





//modification password



const[valid, setValid]= useState('')
              
const[valid2, setValid2]= useState('')
const[supMod, setSupMod]= useState(false) 

const toggleSup = ()=> {
  
 
  setSupMod(!supMod)
  setValid('')
 
}


if(valid!= ''){
 toggleSup()
 


} 



              

const[supMod2, setSupMod2]= useState(false) 

const toggleSup2 = ()=> {
  
 
  setSupMod2(!supMod2)
  setValid2('')
  
}


if(valid2!= ''){
 toggleSup2()
 


} 




function handleModf(event){
    const id= gestauto
    event.preventDefault();
    const currentPassword = secretaire.map((data) => data.password)[0];
    
    if(currentPassword===old){
    
        if(new1===new2){
            setInfo2('')
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upPassword/'+id, {new1,titre}, { headers})
            .then(res => {
                    
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
                toggleSup()

            setValid2('on')
            }).catch(err => console.log(err));
        }else{
           
            setInfo2('Incorrecte')
            setTimeout(() => setInfo2(''), 3000)
        }
    }else{
        setInfo1('Incorrecte')
        setTimeout(() => setInfo1(''), 3000)
    }
}



const [menuVisible, setMenuVisible] = useState(false);

const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  
};





const [showOldPassword, setShowOldPassword] = useState(false);
const [showNewPassword, setShowNewPassword] = useState(false);
const [showConfirmPassword, setShowConfirmPassword] = useState(false);


const toggleOldPasswordVisibility = () => setShowOldPassword(!showOldPassword);
const toggleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);
const toggleConfirmPasswordVisibility = () => setShowConfirmPassword(!showConfirmPassword);


useEffect(() => {
    const handleClickOutside = (event) => {
        if (menuVisible && !document.querySelector('.carD').contains(event.target)) {
            setMenuVisible(false);
        }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [menuVisible]);





const [IDV, setIDV]=useState('')



const[photo, setPhoto]= useState('')
              
const[ph, setPh]= useState(false) 

const toggleInscritUser = ()=> {

    setPhoto('')
    setPh(!ph)
 
}


if(photo!== ''){
 setIDV(photo)
 toggleInscritUser()
 
} 






function handleImportClickUser() {
    const fileInput = document.getElementById(`file-input-${IDV}`);
    if (fileInput) {
      fileInput.click();
    } else {
      console.error(`File input with id 'file-input-${IDV}' not found`);
    }
  }
  
  function handleImageUploadUser(event, id) {
  
    const file = event.target.files[0];
    if (file) {
        if(sidebar){
            showSidebar()
        }
        
      setViewLoader(true)
      const formData = new FormData();
      formData.append('image', file);
      formData.append('id', id);
      toggleInscritUser()
      axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image-dg', formData)
        .then(response => {
          window.location.reload(); // Recharger la page après l'upload
        })
        .catch(error => {
          console.error("Erreur lors de l'upload de l'image:", error);
        });
    }
  }




  function openCameraUser() {
    const video = document.createElement('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
  
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        video.srcObject = stream;
        video.play();
  
        // Créer une modal personnalisée
        const modal = document.createElement('div');
        modal.className = 'camera-modal-custom';
        document.body.appendChild(modal);
        modal.appendChild(video);
  
        // Bouton pour capturer l'image
        const captureButton = document.createElement('button');
        captureButton.className = 'bouton-camera-capture';
        captureButton.innerHTML = '<i class="fas fa-camera"></i>'; // Icône de capture
        modal.appendChild(captureButton);
  
        // Bouton pour annuler
        const cancelButton = document.createElement('button');
        cancelButton.className = 'bouton-camera-annuler';
        cancelButton.innerText = 'Annuler';
        modal.appendChild(cancelButton);
  
        // Centrer l'icône de la caméra
        video.style.display = 'block';
        video.style.margin = '0 auto';
  
        // Capturer l'image
        captureButton.addEventListener('click', () => {
          canvas.width = 300;
          canvas.height = 300;
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
          video.style.display = 'none';
          modal.appendChild(canvas);
  
             
  
          // Bouton pour valider l'image
          const confirmButton = document.createElement('button');
          confirmButton.className = 'bouton-camera-confirmer';
          confirmButton.innerHTML = '<i class="fas fa-check"></i>';
          modal.appendChild(confirmButton);
  
          // Bouton pour reprendre la capture
          const retryButton = document.createElement('button');
          retryButton.className = 'bouton-camera-reprendre';
          retryButton.innerHTML = '<i class="fas fa-redo"></i>';
          modal.appendChild(retryButton);
  
          // Valider et envoyer l'image
          confirmButton.addEventListener('click', () => {
            canvas.toBlob(blob => {
              if (blob) {
                const formData = new FormData();
                formData.append('image', blob, 'profile-image.jpg');
                formData.append('id', IDV);
  toggleInscritUser()
  setViewLoader(true)
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image-dg', formData)
                  .then(() => {
                        
  
               
                   setViewLoader(false) 
                  window.location.reload()
                  
                  })
                  .catch(error => console.error('Erreur lors de l\'upload:', error));
  
                video.srcObject.getTracks().forEach(track => track.stop());
                modal.remove();
              }
            }, 'image/jpeg');
          });
  
          // Masquer le bouton "Annuler" après capture
          cancelButton.style.display = 'none';
  
          // Reprendre la capture
          retryButton.addEventListener('click', () => {
            confirmButton.remove();
            retryButton.remove();
            canvas.remove();
             
            video.style.display = 'block';
            cancelButton.style.display = 'block'; // Réafficher le bouton "Annuler"
            captureButton.style.display = 'block'; // Faire réapparaître l'icône caméra
          });
  
          captureButton.style.display = 'none'; // Masquer l'icône caméra après capture
        });
  
        cancelButton.addEventListener('click', () => {
          video.srcObject.getTracks().forEach(track => track.stop());
          modal.remove();
        });
      })
      .catch(error => console.error('Erreur lors de l\'accès à la caméra:', error));
  }


let load

if(viewLoader==true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}





const [imgP,setImgp]=useState('')
const [idP, setIdp]= useState('')

useEffect(() => {
    
   if(secretaire.length>0){
  
    setImgp(secretaire[0].image)
    setIdp(secretaire[0].id)
   }
   
  }, [secretaire]);



  function handleImportUserClick2(id) {

    if (isIOS) {
      openCameraUserIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
    } else {
    
      const fileInput = document.getElementById(`file-input-${id}`);
      if (fileInput) {
        fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
      } else {
        console.error(`File input with id 'file-input-${id}' not found`);
      }
    }

  
  }





  function openCameraUserIOS2(id) {
    if (isIOS) {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
      fileInput.style.display = 'none'; // Cacher l'input file visuellement
  
      // Gérer la sélection d'une image via l'appareil photo
      fileInput.onchange = function(event) {
        const file = event.target.files[0];
        if (file) {
          if(photo){
            toggleInscritUser()
           }
       showSidebar()
          setViewLoader(true)

          const formData = new FormData();
          formData.append('image', file, 'profile-image.jpg');
          formData.append('id', id); // Remplacez par votre ID de candidat
  
          axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image-dg', formData)
            .then(response => {
              setTimeout(() => {
              
                console.log('Image uploadée avec succès');
                window.location.reload(); // Recharger la page après 3 secondes
              }, 200);
            
            })
            .catch(error => console.error('Erreur lors de l\'upload:', error));
        }
      };
  
      // Ajouter le champ input au document et le déclencher
      document.body.appendChild(fileInput);
      fileInput.click(); // Simuler un clic pour ouvrir la caméra
    }
  }



var bar
const location = useLocation(); 
if(largeurEcran>= 1000){
    bar=         <div className="barHaut">
         <div className="lep">
                    
                    
                   {/*  <img src={moni} className="user" onClick={showProfil} /> */}




                    <img src={imgP ? imgP  : moni} alt="Candidat" className="user" />


{load}


             {ph && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClickUser()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCameraUser()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscritUser}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 

  <input
  type="file"
  id={`file-input-${idP}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, idP)}
/>





                    {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)}
                    
                    <div className="hero"> 
                     <h3 className="nom">{secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h3>
                     
                    </div>
       
                    <div class="options">
  {/*    <h2 className="form-title2"> {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}</h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier</button>
        <button class="option-btn2" onClick={   e => setPhoto(idP)}>Photo de profil</button> */}

{!idP && (
        <h2 className="form-title2"> 
        {secretaire.map((data)=>(data.nom))} {secretaire.map((data)=>(data.prenom))}
        </h2>
      )}
        
        <h2 className="form-title2"> 
     {idP && (
        <PhotoProvider>
          <PhotoView src={imgP}>
          <button class="option-btn10" >Voir profil</button>
          </PhotoView>
        </PhotoProvider>
      )}
        
        </h2>           
    <button class="option-btn" onClick={e => setValid('on')}>Modifier mot de passe</button>
    <button class="option-btn2" onClick={   e => setPhoto(idP)}>Modifier photo de profil</button>
   


  </div>



                    </div>


    <NavLink to={'/accueil2/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Accueil</button> </NavLink>
<NavLink to={'/candidatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Candidats</button> </NavLink>
<NavLink to={'/depenseDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Depenses</button> </NavLink>


<NavLink to={'/payementDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Recettes</button> </NavLink>
<NavLink to={'/bilan/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Bilan Global</button> </NavLink>
<NavLink to={'/optPresenceDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Presence</button> </NavLink>
<NavLink to={'/resultatDir/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Resultats</button> </NavLink>
<NavLink to={'/employe/'+o} style={({isActive})=>{ return{background: isActive? "gray": 'rgb(6, 122, 116)',};} }><button className='pr'> Employés</button> </NavLink>


<Link onClick={logout} className="link">
                               <img src={log} className="am" />
                               <div className="deco">DECONNEXION</div>
                               
                          </Link>  

</div>

}else{
    bar=  <IconContext.Provider value={{color:'#fff'}} >
                
    <div className="icon">
 <div>
 &nbsp;&nbsp;&nbsp;
 <img src={logo} className="userLog"  /> 
 </div>
 <div className="naI">
               {secretaire.map((data)=>(data.auto_ecole))} 
             </div>   
             {load}
 <div className="navbar">
 
    <Link to="#" className={sidebar?  "trois": "menu-bars"}>
        <FaIcons.FaBars onClick={showSidebar} />
    </Link>
 
    &nbsp;&nbsp;&nbsp;
      
 
 </div>
 
 <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
    <ul className="nav-menu-items" >
        <li className="navbar-toggle">
            <Link to="#" className="menu-bars">
               <AiIcons.AiOutlineClose onClick={showSidebar}/>
            </Link>
        </li>
        <div className="carD">
                    <div className="profil">


        {/* <AiIcons.AiOutlineUser className="icone-profil"   onClick={toggleMenu} /> */}

        {imgP ? (
  <img
    src={imgP}
    alt="Profil"
    className="image-profilUser"
    onClick={toggleMenu}
  />
) : (
  <AiIcons.AiOutlineUser className="icone-profil" onClick={toggleMenu} />
)}


<input
  type="file"
  id={`file-input-${idP}`}
  style={{ display: 'none' }}
  onChange={(event) => handleImageUploadUser(event, idP)}
/>


        <div className="infos-utilisateur">
           <p className="inPo"   onClick={toggleMenu}> {secretaire.map((data)=>(data.poste))} </p>
          <p className="inN"  onClick={toggleMenu}> {secretaire.map((data)=>(data.nom))}  {secretaire.map((data)=>(data.prenom))} </p>
        </div>
      </div>


      {menuVisible && (
      <div className="dropdown-menu">
    <button className="option-btnMobi" onClick={e => setValid('on')}>Modifier mot de passe</button>
               {/* Bouton pour voir la photo - s'affiche seulement si l'image existe */}
      {idP && (
        <PhotoProvider>
          <PhotoView src={imgP}>
            <button className="option-btnMobi">Voir profil</button>
          </PhotoView>
        </PhotoProvider>
      )}
         <button className="option-btnMobi2" onClick={ () => isIOS ? handleImportUserClick2(idP) : handleImportUserClick2(idP) }>Modifier photo de profil</button>
   

     </div>
   )}

      </div>
        {sidedataDir.map((item, index) =>{
           const isActive = location.pathname.startsWith(item.path);
          return (
           
            <li key={index} className={isActive ? `${item.cName} active` : item.cName} onClick={showSidebar}>
                             <Link to={item.path+'/'+o}>
                    <span>{item.title}</span>
                 </Link>
                
            </li>
            
          
          )
 
        })}
       <li className="nav-text">
        <Link onClick={logout}  >
             
               <span>Deconnexion</span>
               
          </Link>
          </li> 
    </ul>
 </nav>
 </div>

 {supMod && (<div className='secretModalSup'>
            <div className='secretOverlay'></div>
            <div className='secretModalContent'>
                <div className="secretContainerModal"> 
                    <h3 className="secretFormTitle">MODIFIER MOT DE PASSE</h3>
                    
                    <div className='secretSupD'>
                        <input type="text" name="nom" className="secretInputCmot" id="nom" value={secretaire.map((data) => (data.nom))} readOnly />
                        <input type="text" name="prenom" className="secretInputCmot" id="prenom" value={secretaire.map((data) => (data.prenom))} readOnly />
                    </div>
                    
                    <label htmlFor="oldPassword">Ancien mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showOldPassword ? 'text' : 'password'} name="oldPassword" className="secretInputCmot" id="oldPassword" onChange={e => setOld(e.target.value)} />
                        <span onClick={toggleOldPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info1}</h4>
                    <br/>
                    <label htmlFor="newPassword">Nouveau mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showNewPassword ? 'text' : 'password'} name="newPassword" className="secretInputCmot" id="newPassword" onChange={e => setNew1(e.target.value)} />
                        <span onClick={toggleNewPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <label htmlFor="confirmPassword">Confirmer mot de passe</label><br/>
                    <div style={{ position: 'relative' }}>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="confirmPassword" className="secretInputCmot" id="confirmPassword" onChange={e => setNew2(e.target.value)} />
                        <span onClick={toggleConfirmPasswordVisibility} style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }}>
                            <i className={showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                        </span>
                    </div>
                    <br/>
                    <h4 className="text-danger">{info2}</h4>
                    <br/>
                    <div className="secretSupD">
                        <Link onClick={handleModf}><button className='secretNm1'>Modifier</button></Link>
                        <Link onClick={toggleSup}><button className='secretNm2'>Annuler</button></Link>
                    </div>
                </div>
            </div>
        </div>)}
{supMod2 && (<div className='modal'>
    <div className='overlay'>
        
   
    <div className='modal-content'>
       
      <h2>Mot de passe modifier avec succès</h2>
      <div className='supD'>
     
      <Link onClick={toggleSup2}> <button className='supC' >QUITER</button> </Link>
    
      </div>
     
      </div>
    </div>
   
</div>)} 


 </IconContext.Provider>
}

  const [selectedLoginId, setSelectedLoginId] = useState(null);

          const toggleLoginDetails = (id) => {
            setSelectedLoginId(selectedLoginId === id ? null : id);
          };
         
          







          const [isModalOpen, setIsModalOpen] = useState(false);
          const [currentId, setCurrentId] = useState(null);
          const [fileInputId, setFileInputId] = useState(null); // Pour stocker l'ID du champ de fichier
        
          const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);


          const [ID, setID]=useState('')

          const[modal, setModal]= useState(false) 
          const toggleModal = ()=> {
              setModal(!modal)
          }
           
    
       
   
    
     
     // Fonction pour ouvrir la boîte de dialogue d'importation de fichier
     function handleImportClick() {
       const fileInput = document.getElementById(`file-input-${ID}`);
       if (fileInput) {
         fileInput.click();
       } else {
         console.error(`File input with id 'file-input-${ID}' not found`);
       }
     }
/*
       // Fonction pour ouvrir la boîte de dialogue d'importation de fichier
       function handleImportClick2(id) {
        const fileInput = document.getElementById(`file-input-${id}`);
        if (fileInput) {
          fileInput.click();
        } else {
          console.error(`File input with id 'file-input-${id}' not found`);
        }
      }
     */

     





 

      function handleImportClick2(id) {

        if (isIOS) {
          openCameraIOS2(id); // Ouvrir l'appareil photo pour iOS après la confirmation
        } else {
          const fileInput = document.getElementById(`file-input-${id}`);
          if (fileInput) {
            fileInput.click(); // Ouvrir le gestionnaire de fichiers pour les autres plateformes
          } else {
            console.error(`File input with id 'file-input-${id}' not found`);
          }
        }

      
      }



     // Fonction pour gérer l'upload de fichier
     function handleImageUpload(event, id) {
     
       const file = event.target.files[0];
       if (file) {
/*
        if (file.size > 2 * 1024 * 1024) {
          setIsErrorModalOpen(true); // Ouvrir la modale d'erreur
          return;
        }

 */
        setViewLoader(true)

         const formData = new FormData();
         formData.append('image', file);
         formData.append('id', id);
         if(affInscrit){
          toggleInscrit()
         }
         axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
           .then(response => {
            setTimeout(() => {
             
              window.location.reload(); // Recharger la page après 3 secondes
            }, 100);
           })
           .catch(error => {
             console.error("Erreur lors de l'upload de l'image:", error);
           });
       }
     }
     
     

     
     
     
     
     
     
     
     function openCamera() {
       const video = document.createElement('video');
       const canvas = document.createElement('canvas');
       const context = canvas.getContext('2d');
     
       navigator.mediaDevices.getUserMedia({ video: true })
         .then(stream => {
           video.srcObject = stream;
           video.play();
     
           // Créer une modal personnalisée
           const modal = document.createElement('div');
           modal.className = 'camera-modal-custom';
           document.body.appendChild(modal);
           modal.appendChild(video);
     
           // Bouton pour capturer l'image
           const captureButton = document.createElement('button');
           captureButton.className = 'bouton-camera-capture';
           captureButton.innerHTML = '<i class="fas fa-camera"></i>'; // Icône de capture
           modal.appendChild(captureButton);
     
           // Bouton pour annuler
           const cancelButton = document.createElement('button');
           cancelButton.className = 'bouton-camera-annuler';
           cancelButton.innerText = 'Annuler';
           modal.appendChild(cancelButton);
     
           // Centrer l'icône de la caméra
           video.style.display = 'block';
           video.style.margin = '0 auto';
     
           // Capturer l'image
           captureButton.addEventListener('click', () => {
             canvas.width = 300;
             canvas.height = 300;
             context.drawImage(video, 0, 0, canvas.width, canvas.height);
     
             video.style.display = 'none';
             modal.appendChild(canvas);
     
                
     
             // Bouton pour valider l'image
             const confirmButton = document.createElement('button');
             confirmButton.className = 'bouton-camera-confirmer';
             confirmButton.innerHTML = '<i class="fas fa-check"></i>';
             modal.appendChild(confirmButton);
     
             // Bouton pour reprendre la capture
             const retryButton = document.createElement('button');
             retryButton.className = 'bouton-camera-reprendre';
             retryButton.innerHTML = '<i class="fas fa-redo"></i>';
             modal.appendChild(retryButton);
     
             // Valider et envoyer l'image
             confirmButton.addEventListener('click', () => {
               canvas.toBlob(blob => {
                 if (blob) {
                   const formData = new FormData();
                   formData.append('image', blob, 'profile-image.jpg');
                   formData.append('id', ID);
     toggleInscrit()
                   axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
                     .then(() => {
                           
     
                  
                       
                     window.location.reload()
                     
                     })
                     .catch(error => console.error('Erreur lors de l\'upload:', error));
     
                   video.srcObject.getTracks().forEach(track => track.stop());
                   modal.remove();
                 }
               }, 'image/jpeg');
             });
     
             // Masquer le bouton "Annuler" après capture
             cancelButton.style.display = 'none';
     
             // Reprendre la capture
             retryButton.addEventListener('click', () => {
               confirmButton.remove();
               retryButton.remove();
               canvas.remove();
                
               video.style.display = 'block';
               cancelButton.style.display = 'block'; // Réafficher le bouton "Annuler"
               captureButton.style.display = 'block'; // Faire réapparaître l'icône caméra
             });
     
             captureButton.style.display = 'none'; // Masquer l'icône caméra après capture
           });
     
           cancelButton.addEventListener('click', () => {
             video.srcObject.getTracks().forEach(track => track.stop());
             modal.remove();
           });
         })
         .catch(error => console.error('Erreur lors de l\'accès à la caméra:', error));
     }
     
     
     
     
     
// Détecter si l'utilisateur est sur iOS
const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

// Fonction pour ouvrir la caméra sur iOS
function openCameraIOS() {
  if (isIOS) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
    fileInput.style.display = 'none'; // Cacher l'input file visuellement

    // Gérer la sélection d'une image via l'appareil photo
    fileInput.onchange = function(event) {
      const file = event.target.files[0];
      if (file) {
        toggleInscrit()
        setViewLoader(true)
        const formData = new FormData();
        formData.append('image', file, 'profile-image.jpg');
        formData.append('id', ID); // Remplacez par votre ID de candidat

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
          .then(response => {
            setTimeout(() => {
            
              console.log('Image uploadée avec succès');
              window.location.reload(); // Recharger la page après 3 secondes
            }, 100);
          
          })
          .catch(error => console.error('Erreur lors de l\'upload:', error));
      }
    };

    // Ajouter le champ input au document et le déclencher
    document.body.appendChild(fileInput);
    fileInput.click(); // Simuler un clic pour ouvrir la caméra
  }
}


const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 Mo en bytes
// Fonction pour ouvrir la caméra sur iOS
function openCameraIOS2(id) {
  if (isIOS) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*;capture=camera'; // Ouvre directement l'appareil photo sur iOS
    fileInput.style.display = 'none'; // Cacher l'input file visuellement

    // Gérer la sélection d'une image via l'appareil photo
    fileInput.onchange = function(event) {
      const file = event.target.files[0];
      if (file) {
        if(affInscrit){
          toggleInscrit()
         }

/*
         if (file.size > MAX_FILE_SIZE) {
          // Afficher la modale d'erreur
          setIsErrorModalOpen(true);
          return;
        }
     */
        setViewLoader(true)
        const formData = new FormData();
        formData.append('image', file, 'profile-image.jpg');
        formData.append('id', id); // Remplacez par votre ID de candidat

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/upload-image', formData)
          .then(response => {
            setTimeout(() => {
            
              console.log('Image uploadée avec succès');
              window.location.reload(); // Recharger la page après 3 secondes
            }, 200);
          
          })
          .catch(error => console.error('Erreur lors de l\'upload:', error));
      }
    };

    // Ajouter le champ input au document et le déclencher
    document.body.appendChild(fileInput);
    fileInput.click(); // Simuler un clic pour ouvrir la caméra
  }
}




function openCameraByDevice() {
// Appel de la fonction pour ouvrir la caméra sur iOS
if (isIOS) {
  openCameraIOS(); // Utilise l'input type file pour iOS
} else {
  // Sinon, utilisez votre code habituel avec getUserMedia pour Android
  openCamera();
}

}

     
     
     
     
     const[affInscrit, setAffInscrit]= useState('')
                   
     const[ins, setIns]= useState(false) 
     
     const toggleInscrit = ()=> {
     
         setAffInscrit('')
         setIns(!ins)
      
     }
     
     
     if(affInscrit!== ''){
       setID(affInscrit)
      toggleInscrit()
      
     } 
























if(loader){
    return(
        <div className="per">
        
        <div className="loader">
        <div className="overlayLo"> 
            <div className="loaderP">
            <Loader/>
            </div>
            </div>
            </div>
            </div>
    )
}else if(error){
    return (<div>Une erreur est survenue : {error.message}</div>)
}
else{
if(largeurEcran>= 1024){ 
    return(
        <>
        <div className='wini'>
 
{bar}


        <h1 className='win'>La page de gestion des candidats</h1><br/><br/>
        {load}
        <div className='inove'>
            <div >
                <div className='izi'>
        <div className="search">
             <input type="text" className='recherche' name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value) }  ></input>
             </div>
             </div><br/><br/><br/>
             
             <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
            <input type='date' value={date1} className='oixe' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oixe' onChange={e => setDate2(e.target.value)} ></input> 
            <button type='submit' onClick={handlePropose} className='butonSF6'>Appliquer</button>
           </div>
           
             </div>
             
             <div className='autre'  >
             
             <select name="poste"   className='autreSel' onChange={e => setAnnexe(e.target.value)} >
            <option >{b}</option>
                     
                {  
                    ville.filter((data) => data.ville!=b).map((data)=>(   
                        
                        <option  value={data.ville}  >{data.ville}</option>
                            
                    ))
                    
                }
           </select>   <br/>
           
                <select name="poste" className='autreSel'  onChange={e => setValue(e.target.value)}>
                <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
                   <option value={'Tous les Candidats'}>Tous les Candidats</option>
            
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
            <option value={'Qui ont retiré leurs permis'}>Qui ont retiré leurs permis</option>
            
                </select>

                <button className='butonSF4' onClick={handleSubmit}>Appliquer</button>
           </div>
          
           </div> <br/><br/><br/>
           <Link to={'/createCandidatDir/'+o} className='yello'> <button className='ajouter'>AJOUTER UN CANDIDAT</button> </Link> <br/><br/>
           <div className="card-list20">
{
        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
            <div className={`card20 ${selectedLoginId === data.id ? 'card-expanded' : ''}`} key={i}>
             
             
             
             {/*  <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" /> */}
             
             
                 {/* ici  */}              
                        
          <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

           <div className="camera-icon" onClick={   e => setAffInscrit(data.id)}>
              📷
            </div>
          </div>

{/* Champ caché pour l'upload de fichier */}
         <input
           type="file"
           id={`file-input-${data.id}`}
           style={{ display: 'none' }}
         onChange={(event) => handleImageUpload(event, data.id)}
         />      
                      
            {/*fin */}         
             
             
             
           
         <div className='info'>
            <p className='name'> {data.nom} {data.prenom}</p>
         
            <a href={`tel:+229${data.contact}`} className="contact-link">
            <p className="contacts">
              <FaPhoneAlt className="phone-icon" /> {data.contact}
            </p>
          </a>
               
<div className="login-section">
{selectedLoginId===data.id && (
            <div className="login-details">
             <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              <p>Username: {data.user_name}</p>
              <p>Password: {data.password}</p>
              
            </div>
          )}

               {selectedLoginId !== data.id && (
              <p className="login">
                Login***
                <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              </p>
            )}

</div>  




{/*ici */}


{ins && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClick()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCamera()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscrit}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 


{/*fin */}







            <Link to={'/pageCandidatDir/'+ data.id} >    <button className="modifier-btn">VOIR</button>  </Link>
            </div>
        </div>
    
        ))
      } 
</div>
    <br/>
    </div>
    <Footer/>
    </>
    )}else{
        return(
            <>
            <div className='wini'>
     
    {bar}
    
    
            <h1 className='win'>La page de gestion des candidats</h1><br/><br/>
           




{load}
               <div className='barContener'>

                {/* Barre de recherche */}

<input type="text"className='seLe'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
<br/>
             
<select name="poste"   className='rechBar' onChange={e => setAnnexe(e.target.value)} >
                <option >{b}</option>
                         
                    {  
                        ville.filter((data) => data.ville!=b).map((data)=>(   
                            
                            <option  value={data.ville}  >{data.ville}</option>
                                
                        ))
                        
                    }
               </select> 


{/* Barre de sélection avec bouton Appliquer */}
<div className='barChoixT'>
<select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
    <option value={'Enregistré ce mois'}>Enregistré ce mois</option>
            <option value={'Tous les Candidats'}>Tous les Candidats</option>
            <option value={'Enregistré cette année'}>Enregistré cette année</option>
            
            <option value={'Qui ont retiré leurs permis'}>Qui ont retiré leurs permis</option>
    </select>
{/* Bouton Appliquer pour le select */}
<button className='butonSF' onClick={handleSubmit}>Appliquer</button>
</div>

{/* Barre de recherche personnalisée */}
<div className='persBarT'>

<input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
<input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
<button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>





               <Link to={'/createCandidatDir/'+o} className='yello'> <button className='ajouter'>AJOUTER UN CANDIDAT</button> </Link> <br/><br/>
               <div className=''>
            <div className=''>
              
              
            <div className="card-list20">
{
        candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).reverse().map((data, i)=> (
            <div className={`card20 ${selectedLoginId === data.id ? 'card-expanded' : ''}`} key={i}>
         

         {/*    <img src={data.sexe=='Masculin' ? moni : secre}  alt="Candidat 1" /> */}
         
         
         
             {/* ici  */}              
                        
             <div className="image-container8">
               
                 {data.image && (
        <PhotoProvider>
          <PhotoView src={data.image}>
          
<img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

          </PhotoView>
        </PhotoProvider>
      )}
        {!data.image && (
       
          
<img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

      )}
        
           <div className="camera-icon" onClick={   () => isIOS ? handleImportClick2(data.id) : handleImportClick2(data.id)}>
              📷
            </div>
          </div>

{/* Champ caché pour l'upload de fichier */}
         <input
           type="file"
           id={`file-input-${data.id}`}
           style={{ display: 'none' }}
         onChange={(event) => handleImageUpload(event, data.id)}
         />      
                      
            {/*fin */}         
         
         
         
         
         
         
         
         
         
         
         
         <div className='info'>
            <p className='name'> {data.nom} {data.prenom}</p>
         
            <a href={`tel:+229${data.contact}`} className="contact-link">
            <p className="contacts">
              <FaPhoneAlt className="phone-icon" /> {data.contact}
            </p>
          </a>
               
<div className="login-section">
{selectedLoginId===data.id && (
            <div className="login-details">
             <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              <p>Username: {data.user_name}</p>
              <p>Password: {data.password}</p>
              
            </div>
          )}

               {selectedLoginId !== data.id && (
              <p className="login">
                Login***
                <button onClick={() => toggleLoginDetails(data.id)} className="eye-button">
                  {selectedLoginId === data.id ? '👁️' : '👁️‍🗨️'}
                </button>
              </p>
            )}

</div>



{/*ici */}


{ins && ( <>
    <div className="overlay-pop"></div>
    <div className="fenetre-modal">
      <div className="modal-entete">
      
      </div>
      <div className="modal-corps">
      <div className="upload-options">
    {/* Option pour importer une image */}
    <button className="import-btn" onClick={() => handleImportClick()}>
      📁 Importer une image
    </button>

    {/* Option pour prendre une photo */}
    <button className="camera-btn" onClick={() => openCamera()}>
      📷 Prendre une photo
    </button>
  </div>
      </div>
      <div className="boutons-modal">
        <Link onClick={toggleInscrit}>
          <button className="bouton-annuler">QUITTER</button>
        </Link>
      </div>
    </div>
  </>)} 

  

   {/* Modale pour confirmer l'upload 
 <Modal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
        onConfirm={handleConfirmOpenFileDialog} 
      />
*/}
   {/* Modale pour afficher l'erreur 
   <ErrorModal 
        isOpen={isErrorModalOpen} 
        onClose={() => setIsErrorModalOpen(false)} 
      />
*/}
{/*fin */}


{/*fin */}




            <Link to={'/pageCandidatDir/'+ data.id} >    <button className="modifier-btn">VOIR</button>  </Link>
            </div>
        </div>
    
        ))
      } 
</div>

              
              
              
                                         </div>
            
        </div>
        <br/>
        </div>
        <Footer/>
        </>
        )
    }
}
    }
