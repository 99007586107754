

export const sidedata =[ {
    title: 'Accueil',
    path: '/accueil',
    cName: 'nav-text'
},
{
    title: 'Candidats',
    path: '/candidat',
    cName: 'nav-text'
},
{
    title: 'Depenses',
    path: '/depense',
    cName: 'nav-text'
},
{
    title: 'Recettes',
    path: '/payement',
    cName: 'nav-text'
},
{
    title: 'Resultats',
    path: '/resultat',
    cName: 'nav-text'
},


]