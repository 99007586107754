import React from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ element: Component, allowedRoles, ...rest }) => {
  const userRole = Cookies.get('titre'); // Récupère le rôle de l'utilisateur depuis le cookie

  // Vérifie si l'utilisateur a un rôle autorisé pour accéder à cette route
  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/" replace />; // Redirige vers la page d'accueil si non autorisé
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;
