import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../components/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
function Update(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      const [loader, setLoader]= useState(false);
    const [name, setName]= useState('')
    const [prenom, setPrenom]= useState('')
    const [dure, setDure]= useState('1 mois')
    const [langue, setLangue]= useState('Français')
    const [total, setTotal]= useState('')
    const [contact, setContact]= useState('')
    const [sexe, setSexe]= useState('')
    const [adresse, setAdresse]= useState('')
    const [age,setAge]= useState('')
    const [montant, setMontant]= useState('')
    const [statut, setStatut]= useState('')
    const [date, setDate]= useState('')
    const {id}= useParams();
    const [info, setInfo]= useState('')
    const [control, setControl]= useState('')
    const [dateControl, setDateControl]= useState('')
    const navigate = useNavigate();

    var date2= new Date();
        var autre= date2.toISOString().split('T')[0]  
     

    function handleSubmit(event){
        setControl('')
            setInfo('')
            setLoader(true)
        event.preventDefault();
        if(name=="" || prenom=="" || contact=="" || sexe==""|| montant==""  || adresse=="" || date==""|| total=="" ){
            setLoader(false)
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
            
         }else  if(contact.length<8){
            setLoader(false)
            setControl('CONTACT INCORECTE')}
          else  if(date>autre){
            setLoader(false)
                setDateControl('Verifier la date')
            }else if(montant<=0 || total<=0 || montant-total > 0){
                setLoader(false);
                setInfo('VEILLEZ VERIFIER LA VALEUR DU MONTANT');
            }
         else{
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, {name,prenom,contact,sexe, adresse,montant,date,statut,total,dure,langue}, { headers})
        .then(res => {
            setLoader(false)
            navigate('/candidat');
        }).catch(err => console.log(err));
        
       
    }
    }
    
    
    const handleGoBack = () => {
        navigate('/candidat');
      };

    

    const [candidat, setCandidat] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
        .then(res => { 
            setName(res.data[0].nom)
            setPrenom(res.data[0].prenom)
            setContact(res.data[0].contact)
            setSexe(res.data[0].sexe)
            setAdresse(res.data[0].adresse)
            setAge(res.data[0].age)
            setMontant(res.data[0].montant_payer)
            setStatut(res.data[0].statut_payement)
            setDate( moment(res.data[0].date).utc(1).format('YYYY-MM-DD'))
            setTotal(res.data[0].montant_total)
            setCandidat(res.data)
            setLangue(res.data[0].langue)
            setDure(res.data[0].dure)
        })
            
        .catch(err => console.log(err));
    }, [])
 
    
    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }
    
    

    return(

         




<div className="auths">
            
            <body className="body">
            
            <div className="container"> 
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
            <h2 className="form-title">MISE A JOUR</h2>
                                 
                 <form action="#" onSubmit={handleSubmit}>
                 
                   <div className="main-user-info">
                    <div className="user-input-box">
                 

                    <label htmlFor="fullName" className="">Nom</label>
                    
                    <input type="text" id="fullName" name="fullName" className="" value={name}   onChange={e => setName(e.target.value)}  ></input>
                    </div>
                    <div className="user-input-box">
                    <label htmlFor="username" className="">Prenom</label>
                    <input type="text"  className="" name="username" id="username" value={prenom} onChange={e => setPrenom(e.target.value)}></input>
                    </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="contact" className="">Contact</label>
                    <h4 className="text-danger">{control}</h4>
                    <input type="number" name="contact" id="contact" className="" value={contact} onChange={e => setContact(e.target.value)}></input>
                    </div>
                   
                    <div className="user-input-box">
                    <label htmlFor="adresse" className="">Adresse</label>
                    <input type="text" name="adresse" className="" id="adresse" value={adresse} onChange={e => setAdresse(e.target.value)}></input>
                    
                    </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="montantPayer" className="">Montant Payé</label>
                    <input type="number" name="montantPayer" className="" id="montantPayer" value={montant} onChange={e => setMontant(e.target.value)}></input>
                    
                    </div>
                    <div className="user-input-box">
                    <label htmlFor="fraisDeFormation" className="">Montant Total</label>
                    <input type="number" name="fraisDeFormation" className="" id="fraisDeFormation" value={total} onChange={e => setTotal(e.target.value)}></input>
                    
                    </div>
                    
                     
                     <div className="user-input-box">
                     <label htmlFor="date" className="">Date</label>
                     <h4 className="text-danger">{dateControl}</h4>
                    <input type="date" value={date} name="date" id="date" className="" onChange={e => setDate(e.target.value)}></input>
                     </div>

                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Langue</label>
                     <select name="langue" className='' onChange={e => setLangue(e.target.value)} >
             
                     <option value={'ADJA'} selected={langue=='ADJA'? true:false}>ADJA</option>
                     <option value={'BARIBA'} selected={langue=='BARIBA'? true:false}>BARIBA</option>
                     <option value={'DINDI'} selected={langue=='DINDI'? true:false}>DINDI</option> 
                     <option value={'FON'} selected={langue=='FON'? true:false}>FON</option>
                     <option value={'Français'} selected={langue=='Français'? true:false}>Français</option>
                     <option value={'GOUN'} selected={langue=='GOUN'? true:false}>GOUN</option>
                     <option value={'MINA'} selected={langue=='MINA'? true:false}>MINA</option>
                     <option value={'YORUBA'}  selected={langue=='YORUBA'? true:false}>YORUBA</option>
                                      
            </select>
                     </div>

                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Durée de la formation</label>
                     <select name="dure" className='' onChange={e => setDure(e.target.value)} >
                     <option value={'1 mois'} selected={dure=='1 mois'? true:false}>1 Mois</option>
                    <option value={'2 mois'} selected={dure=='2 mois'? true:false}>2 Mois</option>
                    <option value={'3 mois'} selected={dure=='3 mois'? true:false}>3 Mois</option>
                    <option value={'4 mois'} selected={dure=='4 mois'? true:false}>4 Mois</option>
                    <option value={'5 mois'} selected={dure=='5 mois'? true:false}>5 Mois</option>
                    <option value={'6 mois'} selected={dure=='6 mois'? true:false}>6 Mois</option>   
                    <option value={'12 mois'} selected={dure=='12 mois'? true:false}>6 Mois</option>          
                     
            </select>
                     </div>

                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Sexe</label>
                     <select name="sexe" className='' onChange={e => setSexe(e.target.value)} >
             
                         <option value={'Masculin'} selected={sexe=='Masculin'? true:false}>Masculin</option>
                         <option value={'Feminin'} selected={sexe=='Feminin'? true:false}>Feminin</option>
                                 
            </select>
                     </div>



                     </div> 

                 
                     <h4 className="text-danger">{info}</h4>
                    
                    <div class="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" ></input>
                    </div>
                    
                   
                    
                 </form>
                 
            </div> 
            </body>
            {load}
        </div>








       
    )
}
export default Update


