import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/loader";
import Profil2 from 'F:/projet/first/src/components/profile2';


import Footer from '../components/footer';
import Option2 from "../components/option2";

function PageCandidat(){

    const [loader, setLoader]= useState(true)
      
    return(
        <body>

          
<div className="pC"> 

CANDIDAT </div>
        <div className="" >
           
                <div className="disposition">
                 <Profil2/>
                 
              <Option2/>
                 </div>
                     
               

                         </div>
                         <Footer/>
        </body>
    )
}
export default PageCandidat