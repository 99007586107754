import React, {useEffect, useState } from "react"
import axios from "axios";
import {  useNavigate, useParams } from "react-router-dom";


export default function Rese(){
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };
         const [query, setQuery]= useState(""); 
         console.log(query); 
         const [stat,setStat]= useState("ECHEC")
         const [le, setLe]= useState('')
         const [name, setName]= useState('')
         const [prenom, setPrenom]= useState('')
         const [total, setTotal]= useState('')
         const [contact, setContact]= useState('')
         const [sexe, setSexe]= useState('')
         const [adresse, setAdresse]= useState('')
         const [age,setAge]= useState('')
         const [montant, setMontant]= useState('')
         const [statut, setStatut]= useState('')
         const [date, setDate]= useState('')
         const [nombre, setNombre]= useState([])
         const [nombre2, setNombre2]= useState([])
         const {id}= useParams();
         
        
         const [payement, setPayement]= useState([])
         
         
        
     
         
        
         
         
     
     
     
         const [candidat, setCandidat] = useState([])
         useEffect(()=>{
             axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
             .then(res => { 
                 setLe(res.data[0].id)
                 setName(res.data[0].nom)
                 setPrenom(res.data[0].prenom)
                 setContact(res.data[0].contact)
                 setSexe(res.data[0].sexe)
                 setAdresse(res.data[0].adresse)
                 setAge(res.data[0].age)
                 setMontant(res.data[0].montant_payer)
                 setStatut(res.data[0].statut_payement)
                 setDate(res.data[0].date)
                 setCandidat(res.data)
                 setTotal(res.data[0].montant_total)
                 if(res.data[0].resultat_conduite>=14){
                    setStat('ADMIS')
                }
             
             }
                 
                 
                 
            
             
                
     
     
                 
                 )
             .catch(err => console.log(err));
     
         
         }, [])
         
         axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/paye',{name,prenom}, { headers})
         .then(res => setPayement(res.data)
     ).catch(err => console.log(err))
         
     let totalPaye=0
     const first=montant
     
     
     
     payement.map((data, i)=> ( 
                     
         totalPaye=totalPaye+data.montant    
     
     ))
        
     
     
        
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre',{le}, { headers})
        .then(res => setNombre(res.data)
     ).catch(err => console.log(err))
       
     axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre2',{le}, { headers})
        .then(res => setNombre2(res.data)
     ).catch(err => console.log(err))


        

    
    
    
       
    






    
    return(


        <>
    
   <div className="bian">
   <div>
   
                 <table className='ta'>
         <thead>
         <tr>
            <th> Resultat cour de Code</th>                    
              
            
            
            <th>Resultat cour de Conduite </th>  
            <th>Statut</th>  
            <th>observation</th>                 
              </tr>
            </thead>
            <tr> 
              {  
                candidat.map((data, i)=> ( 
                           
                        <td className='th'>{data.resultat_code}</td>                                              
                         
                ))
              } 
               {  
                candidat.map((data, i)=> ( 
                           
                        <td className='th'>{data.resultat_conduite}</td>                                              
                         
                ))
              } 
              <td className="th">{stat}</td>
              {  
                candidat.map((data, i)=> ( 
                           
                        <td className='th'>{data.observation}</td>                                              
                         
                ))
              } 
              </tr> 
     </table>
     </div>
     <div>
    
     
     </div>
     </div>
        </>
    )
}