import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import jsPDF from 'jspdf';
import moment from "moment";
import 'moment/locale/fr';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
export default function PageCandidatPaye() {
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };

    const [loader, setLoader]= useState(false);
    const [name, setName] = useState('')
    const [prenom, setPrenom]= useState('')
    const [id_annexe,setId_annexe]= useState('')
    const [libelle, setLibelle]= useState('')
    const [montant, setMontant]= useState('')
    const [ecole, setEcole]= useState('')
    const navigate = useNavigate();
    const [info, setInfo]= useState('')
    const [lapaye, setLapaye]= useState('')
    const [dateControl, setDateControl]= useState('')
    const [sum,setSum]= useState('')
    const [sum2,setSum2]= useState('')
    const [solde,setSolde]= useState('')
    const [montant_payer, setMontant_payer]= useState('')
    const [montant_total, setMontant_Total]= useState('')
    const {id}= useParams();
    const [dure, setDure]= useState('')
    const [langue, setLangue]= useState('')
    const [categorie, setCategorie]= useState('')
    var date2= new Date();
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto')) 
    const [liste,setListe]= useState([])
   var autre= date2.toISOString().split('T')[0]  
   const [date, setDate]= useState(autre)
   const maintenant = new Date();
   const heure = maintenant.getHours();
   const minutes = maintenant.getMinutes().toString().padStart(2, '0');
   const secondes = maintenant.getSeconds().toString().padStart(2, '0');
   const [secreta, setSecreta] = useState([])
   const [id_ecole_cand, setId_ecole_cand]= useState('')
   const [infoAuto, setInfoAuto] = useState('')
   const [localisation, setLocalisation] = useState('')
   const [logoA, setLogoA]= useState('')
   const [rcm, setRcm] = useState('')
   const [ifu, setIfu] = useState('')
   const [autorisation, setAutorisation] = useState('')
   const [mail, setMail] = useState('')
const [time, setTime]= useState('')
const [NB,setNB]= useState('')
    const [autoName, setAutoName]= useState('')

   useEffect(()=>{
       axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
       .then(res => setSecreta(res.data))
       .catch(err => console.log(err));
   }, [])
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
       
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/createPayement?id='+gestauto+'', { headers})
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])



    useEffect(()=>{
     
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/infoAuto?id='+id_ecole_cand+'&titre='+titre+'', { headers })
        .then(res => {
            setInfoAuto(res.data[0].contact)
            setLocalisation(res.data[0].localisation)
           setLogoA(res.data[0].logo)
           setRcm(res.data[0].rcm)
           setIfu(res.data[0].ifu)
           setAutorisation(res.data[0].autorisation)
           setMail(res.data[0].mail)
           setAutoName(res.data[0].nom)
           setNB(res.data[0].nb)
        })
        .catch(err => console.log(err));
    }, [id_ecole_cand])




    const loadImageAsBase64 = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      };






    const [candidat, setCandidat] = useState([])
     const [id_ecole]= candidat.map((data)=>(data.id_ecole));

     let safe = 0


     
     if(id_ecole && safe===0){
     axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/infoEcole?id='+id_ecole+'')
     .then(res => setEcole(res.data[0].nom))
     .catch(err => console.log(err));

     safe=1
 
     }
     useEffect(()=>{
     axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/lapaye2', {id}, { headers})
     .then(res => {
        setMontant_Total(res.data[0].montant_total)
        setMontant_payer(res.data[0].montant_payer)
    }).catch(err => console.log(err));
}, [])


useEffect(()=>{
axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vefMontantDir', {id}, { headers})
            .then(res => {
                setSum(res.data[0].sum)
            }).catch(err => console.log(err));
        }, [])   

        useEffect(()=>{
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/seePaiements', {id}, { headers})
                        .then(res => {
                          setListe(res.data)
                        }).catch(err => console.log(err));
                    }, [])   
            

//ici




       // Fonction pour générer un identifiant aléatoire de 5 chiffres
       const generateRandomId = () => {
        let result = '';
        for (let i = 0; i < 5; i++) {
          result += Math.floor(Math.random() * 10); // Génère un chiffre aléatoire entre 0 et 9
        }
        return result;
      };
            // Fonction pour générer un timestamp de 6 caractères
    const generateTimestamp = () => {
        const timestamp = ('000000' + Date.now()).slice(-6); // Obtenir les 6 derniers caractères du timestamp
        return timestamp;
      };
    
    
     // Générer un identifiant aléatoire de 4 caractères
     const randomId = generateRandomId().padEnd(5, '0'); // Remplir avec des zéros si nécessaire
    
    
    
      const generatePaymentReference = () => {
        // Générer un timestamp avec 6 caractères
        const timestamp = generateTimestamp();
      
     
      
        // Concaténer le timestamp et l'identifiant aléatoire de manière aléatoire
      const randomNumber = Math.random();
      const paymentReference = randomNumber > 0.5 ? `${timestamp}${randomId}` : `${randomId}${timestamp}`;
    
     
      
        return paymentReference;
      };
    
      const reference = generatePaymentReference();
    
      
    
           
      const generatePDF = async(name, prenom,  montant, total,paye, date,referencePaiement,imageUrl,localisation,infoAuto) => {
        // Créer une nouvelle instance de jsPDF
        const doc = new jsPDF();
        const reste = total - paye;
      


        try {
            if (imageUrl) {
              const base64Logo = await loadImageAsBase64(imageUrl); // Utilisation de await pour charger l'image
              doc.addImage(base64Logo, 'PNG', 20, 10, 30, 30);
            }
          } catch (error) {
            console.error("Erreur lors du chargement de l'image :", error);
        
          }




          doc.setFontSize(12);
          doc.setTextColor(0, 0, 0); // Couleur noire
          
          doc.text(`Auto Ecole ${autoName}`, 105, 15, null, null, 'center');
          
          if (infoAuto) {
          doc.setFontSize(10);
          doc.text(`TEL : ${infoAuto}`, 105, 20, null, null, 'center');
          }
          if (mail) {
              doc.text(`Email : ${mail}`, 105, 25,null,null,'center');
          
              if (localisation) {
                  // Limite de caractères par ligne (ajustez si nécessaire)
                  const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges
              
                  // Découpe le texte en plusieurs lignes si nécessaire
                  const locationText = ` ${localisation}`;
                  const lines = doc.splitTextToSize(locationText, maxLineWidth);
              
                  // Dessine chaque ligne sur le PDF
                  doc.setFontSize(10);
                  doc.setTextColor(0, 0, 0); // Couleur noire
                  lines.forEach((line, index) => {
                      doc.text(line, 105, 30 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
                  });
              }
           
          }else{
              if (localisation) {
                  // Limite de caractères par ligne (ajustez si nécessaire)
                  const maxLineWidth = 120; // Largeur maximale pour la ligne, ajustez selon vos marges
              
                  // Découpe le texte en plusieurs lignes si nécessaire
                  const locationText = ` ${localisation}`;
                  const lines = doc.splitTextToSize(locationText, maxLineWidth);
              
                  // Dessine chaque ligne sur le PDF
                  doc.setFontSize(10);
                  doc.setTextColor(0, 0, 0); // Couleur noire
                  lines.forEach((line, index) => {
                      doc.text(line, 105, 25 + (index * 5), null, null, 'center'); // Ajuste l'espacement entre les lignes
                  });
              }
          }
          


    
        // Définir la police et la taille du texte
        doc.setFont('helvetica', 'normal');
      
        // Insérer le logo et le nom du système
        doc.setFontSize(20);
        doc.setTextColor(25, 25, 112);
        doc.text('Quittance de Paiement', 105, 48, null, null, 'center');
      
      
     
      
        // Date
        doc.setFontSize(11);
        doc.text(`Date: ${moment(date).utc(1).format('DD-MM-YYYY')}`, 20, 55);
        doc.text(`Heure: ${heure}:${minutes}:${secondes}`, 20, 60);


        if (ifu) {
            doc.setFontSize(9);
            doc.text(`IFU : ${ifu}`, 105, 55);
          
        }
        if (rcm) {
            doc.setFontSize(9);
            doc.text(`N°RCCM : ${rcm}`, 105, 60);
          
        }

        if (autorisation) {
            doc.setFontSize(9);
            doc.text(`Référence de l’autorisation : ${autorisation}`, 105, 65);
           
        }





        // Ligne de séparation
        doc.setLineWidth(0.5);
        doc.line(20, 68, 190, 68);
      
        // Nom et prénom du payeur et référence de paiement
        doc.setFontSize(11);
  
        doc.setFont('helvetica', 'bold');

doc.text(`${prenom} ${name}`, 31, 75);



doc.setFontSize(11);
doc.setTextColor(25, 25, 112);
doc.setFont('helvetica', 'normal');
if(categorie){
doc.text(`Catégorie: Permis ${categorie}`, 20, 80);
}
        doc.text(`Langue de Formation: ${langue}`, 20, 85);
        doc.text(`Durée de Formation: ${dure}`, 20, 90)
        doc.text(`Référence de Paiement: ${referencePaiement}`, 105, 80);
      
        // Tableau des informations
        const data = [
         
          ['Frais de Formation', `${total} FCFA`],
           ['Versement 1', `${montant_payer} FCFA`],
       
        ];



         if (liste.length > 0) {
            liste.forEach((element, index) => {
                data.push([`Versement ${index + 2}`, `${element.montant} FCFA`]); // Assurez-vous que `montant` est la bonne clé
            });
        }

         data.push(
      ['Montant payé', `${montant} FCFA`],
      ['Reste à payer', `${reste} FCFA`]
    );

      
        // Utiliser autoTable pour générer le tableau
        doc.autoTable({ startY: 100,head: [['Désignation', 'Valeur']], body: data });
      // Calcul du montant restant
      doc.setFontSize(11);
doc.text(`Arrêté le present reçu à la somme de: ${montant} FCFA`, 20, doc.autoTable.previous.finalY + 10);

// Ligne de signature
doc.setFontSize(11);
doc.text(`Signature ${titre}:`, 20, doc.autoTable.previous.finalY + 20);

if(NB){
    doc.setFontSize(11);
    doc.setTextColor(255, 0, 0);
    doc.text(`NB: ${NB}`, 105, doc.autoTable.previous.finalY + 30, 'center');

  }else{
    doc.setFontSize(11);
    doc.setTextColor(255, 0, 0);
    doc.text(`NB: `, 105, doc.autoTable.previous.finalY + 30, 'center');
  }


        // Sauvegarder le PDF
        doc.save(`${prenom}_${name}_quittance.pdf`);
      };




//fin

useEffect(()=>{
    setTime(`${heure}:${minutes}:${secondes}`);
}, [heure, minutes, secondes])


    function handleSubmit(event){
        const referencePaiement = generatePaymentReference();
        setInfo('')
        setLapaye('')
        event.preventDefault();
        if( montant=="" || libelle=="" || date=="" ){
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
         }else if(montant<=0){
           setInfo('VERIFIER LA VALEUR DU MONTANT')
         }
         else if(date>autre){
            setDateControl('Verifier la date')
        }else  if (!/^\d+$/.test(montant)) {
            setInfo("Veuillez entrer un montant entier sans virgule.");
           

          }
         else{


            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/lapaye', {name,prenom}, { headers})
        .then(res => {
            
            if(res.data.message){
                setLapaye(res.data.message);
                
                
            }else{

                const d= res.data[0].montant_payer
                
                const f= d;
          
                if(sum+montant_payer== montant_total){
                    setInfo('CE CANDIDAT A SOLDE SES FRAIS')
    
                    }else
                if(sum+ montant_payer+Number(montant)< montant_total){
                    const logoUrl = logoA ? `https://gestauto-56bfcbe4b876.herokuapp.com/get-image?logo=${logoA}` : null;


                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/createPayement2', {name,prenom,libelle,date,montant,id_ecole,f,id_annexe,id,referencePaiement,time}, { headers})
                .then(res => {
              
                   
                    if(secreta.map((data)=>(data.poste))=='secretaire'){
                        generatePDF(name, prenom, montant, montant_total,sum+montant_payer+Number(montant),date,referencePaiement,logoUrl,localisation,infoAuto);
                        navigate('/pageCandidat/'+id);

                      }else if(secreta.map((data)=>(data.poste))=='directeur'){
                        generatePDF(name, prenom, montant, montant_total,sum+montant_payer+Number(montant),date,referencePaiement,logoUrl,localisation,infoAuto);
                        navigate('/pageCandidatDir/'+id);
                    }
                 
                    
                }).catch(err => console.log(err));
            }else if(sum+montant_payer+Number(montant)> montant_total){
                setInfo('ATTENTION LE MONTANT EST SUPERIEUR AU RESTE A PAYER!!!')
            }else if(sum+ montant_payer+Number(montant)== montant_total){
                const logoUrl = logoA ? `https://gestauto-56bfcbe4b876.herokuapp.com/get-image?logo=${logoA}` : null;

                setSolde('oui')
                axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/solde/'+id, {solde}, { headers})
                .then(res => {
               
                    
                }).catch(err => console.log(err));

                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/createPayement2', {name,prenom,libelle,date,montant,id_ecole,f,id_annexe,id,referencePaiement,time}, { headers})
                .then(res => {
               
                    
                   
                    if(secreta.map((data)=>(data.poste))=='secretaire'){
                        generatePDF(name, prenom, montant, montant_total,sum+montant_payer+Number(montant),date,referencePaiement,logoUrl,localisation,infoAuto);
                        navigate('/pageCandidat/'+id);
                      }else if(secreta.map((data)=>(data.poste))=='directeur'){ 
                        generatePDF(name, prenom, montant, montant_total,sum+montant_payer+Number(montant),date,referencePaiement,logoUrl,localisation,infoAuto);
                        navigate('/pageCandidatDir/'+id);
                    }
                 
                    
                }).catch(err => console.log(err));
            }
           
            }
       
            
        }).catch(err => console.log(err));
       
         }

         
    }

    const handleGoBack = () => {
        if(secreta.map((data)=>(data.poste))=='secretaire'){
           navigate('/pageCandidat/'+id);
          }else if(secreta.map((data)=>(data.poste))=='directeur'){ 
            navigate('/pageCandidatDir/'+id);
        }
      };

    
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
        .then(res => { 
            
            setName(res.data[0].nom)
            setPrenom(res.data[0].prenom)
            setId_annexe(res.data[0].id_annexe)
            setCandidat(res.data)
            setCategorie(res.data[0].categorie)
            setDure(res.data[0].dure)
            setLangue(res.data[0].langue)
            setId_ecole_cand(res.data[0].id_ecole)
           }
    
            )
        .catch(err => console.log(err));
    }, [])

        
    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }

    return(
        
          <div className="auths"> 
        <body className="body">
        <div className="container">
        <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
<h2 className="form-title">Ajout d'un paiement</h2>
     <form onSubmit={handleSubmit} action="#">
     
     <div className="main-user-info">
      <div className="user-input-box">
      <label htmlFor="fullname" className="">Nom</label>
        
        <input type="text" name="fullName" className="upo" id="fullName" value={candidat.map((data)=>(data.nom))}  ></input>
       
        </div> 
      
     <div className="user-input-box">
     <label htmlFor="username" className="">Prenom</label>
        <input type="text" name="fullName" className="upo" id="fullName" value={candidat.map((data)=>(data.prenom))} ></input>
        
        </div>   
        
        <div className="user-input-box">
        <label htmlFor="date" className="">DATE</label>
        <h4 className="text-danger">{dateControl}</h4>
        <input type="date" value={date} name="date" className=""  id="date" onChange={e => setDate(e.target.value)}  ></input>
       
        </div>
        
        <div className="user-input-box">
        <label htmlFor="montant" className="">Montant</label>
        <input type="number" name="montant"  className="" id="montant" onChange={e => setMontant(e.target.value)}></input>
        
        </div>
        <div className="libe">
        <label htmlFor="libelle" className="">Libelle</label>
        <input type="text" name="libelle" className="" id="libelle" maxLength={100} onChange={e => setLibelle(e.target.value)}></input>
     
        </div>
        
        </div>
        <h3 className="text-danger">{lapaye}</h3><br/>
        <h4 className="text-danger">{info}</h4>
        <div className="form-submit-btn">
        <input type="submit" className='' value="ENREGISTRER" ></input>
        
        </div>
        

     </form>
</div> 

            </body>
            {load}
            </div>
    
    )
}



