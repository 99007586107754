import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../components/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
function UpdateDepense(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [montant, setMontant]= useState('')
    const [libelle, setLibelle]= useState('')
    const [date, setDate]= useState('')
    const [info, setInfo]= useState('')
    const {id}= useParams();
    const [dateControl, setDateControl]= useState('')
    const navigate = useNavigate();
    const [loader, setLoader]= useState(false);
    var date2= new Date();
 
        var autre= date2.toISOString().split('T')[0]  
     


    function handleSubmit(event){
        setInfo('')
        setLoader(true)
        event.preventDefault();

        if( montant=="" || libelle=="" || date==""){
            setLoader(false)
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
         } else if(date>autre){
            setLoader(false)
            setDateControl('Verifier la date')
        }else if(montant<= 0){
            setLoader(false)
            setInfo('VERIFIER LA VALEUR DU MONTANT')
        }
         else{
    
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/updateDepense/'+id, {date,libelle,montant}, { headers})
            .then(res => {
                setLoader(false)
                navigate('/depense');
            }).catch(err => console.log(err));
    }
    }

   
    const handleGoBack = () => {
        navigate('/depense');
      };


    const [candidat, setCandidat] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/updateDepense/'+id, { headers})
        .then(res => {
            setMontant(res.data[0].montant)
            setLibelle(res.data[0].libelle)
            setDate(moment(res.data[0].date).utc(1).format('YYYY-MM-DD'))
             setCandidat(res.data)})
        .catch(err => console.log(err));
    }, [])


    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }

    return(
        <div className="auths">
        
         
        <body className="body">
        
        
        
        <div className="container">
        <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
           <h2 className="form-title">Modification d'une depense</h2>
                <form action="#" onSubmit={handleSubmit}>
                  <div className="main-user-info">
        
        
                  <div className="user-input-box">
                  <label htmlFor="montant" className="">Montant</label>
                   <input type="number" name="montant" className="" value={montant} id="montant" onChange={e => setMontant(e.target.value)}></input>
                   
                  </div>
        
                  <div className="user-input-box">
                  <label htmlFor="date" className="">DATE</label>
                  <h4 className="text-danger">{dateControl}</h4>
                   <input type="date"  name="date" className=""  id="date" onChange={e => setDate(e.target.value)}  value={date}></input>
                   
                  </div>
        
                  <div className="libe">
                  <label htmlFor="libelle" className="">Libelle</label>
                   <input type="text" name="libelle" className="" id="libelle" value={libelle} maxLength={100} onChange={e => setLibelle(e.target.value)}></input>
                   
                  </div>
                   
                  </div>
                   
                   <h4 className="text-danger">{info}</h4>
                   <div className="form-submit-btn">
                   <input type="submit" className='' value="ENREGISTRER" ></input>
                   </div>
                </form>
           </div> 
            <br/><br/><br/>
            </body>
            {load}
        </div>
    )
}
export default UpdateDepense
