import React from 'react';


const ErrorModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Erreur ⚠️</h2>
        <p>La taille de votre image dépasse 2 Mo. Sélectionnez une image plus petite.</p>
        <div className="popup-buttons">
          <button className='pop-ok' onClick={onClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default ErrorModal;
