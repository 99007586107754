

export const sidedataMoni =[ {
    title: 'Accueil',
    path: '/accueil',
    cName: 'nav-text'
},
{
    title: 'Candidats',
    path: '/candidatMoni',
    cName: 'nav-text'
},


{
    title: 'Resultats',
    path: '/moni',
    cName: 'nav-text'
},


{
    title: 'Presence',
    path: '/presence',
    cName: 'nav-text'
},

]