export default function Loader(){
    return (
        <>
        <div class="dotUP">
    <div class="dotDot"></div>
    <div class="dotDot"></div>
    <div class="dotDot"></div>
    <div class="dotDot"></div>
    <div class="dotDot"></div>
    <div class="dotDot"></div>
    <div class="dotDot"></div>
    <div class="dotDot"></div>
</div>
        
        </>
    )
}