import React, {useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from 'F:/projet/first/src/components/navbar';
import Loader from "../components/loader";
import Profil from 'F:/projet/first/src/components/profil';
import Option from 'F:/projet/first/src/components/option';


import Footer from '../components/footer';

function PageCandidat(){
    const [loader, setLoader]= useState(true)
   
    useEffect(() => {
   
        setTimeout(() => {
          setLoader(false); 
        }, 3000); 
      }, []);




    if(loader){
        return(
            <div className="per">
            
            <div className="loader">
            <div className="overlayLo"> 
                <div className="loaderP">
                <Loader/>
                </div>
                </div>
                </div>
                </div>
        )
    }else{
    return(
        <body>

          

        <div className="" >
            <Navbar/>
           
            
        
             
                
                
                <div className="disposition">
                 <Profil/>
                 
                 <Option/>
                 </div>
                     
               

                         </div>
                         <Footer/>
        </body>
    )
}
}
export default PageCandidat