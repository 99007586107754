


export default function Footer(){
    return (
      <div className="footer">
        <div className="footS">
        <div className="propos">
        <div className="oblo">A PROPOS DE NOUS</div><br/>
        <p className="block">

        GestAuto est une application web dédiée à la gestion d'auto-écoles qui offre une solution complète et intuitive pour simplifier tous les aspects de votre établissement. 
</p>
</div>
<br/>
<div>
<div className="contUS">CONTACTEZ-NOUS</div><br/>
<div>
    <ul className="lisettt">
        <li>Togoudo, Abomey-Calavi, BENIN</li><br/>
        <li>Email: contact@seg.solutions</li><br/>
        <li>Phone: 00229 96292237/ 60220000</li>

    </ul>
    </div>
</div>
<br/>
<div>

<div className="contUS">REALISATIONS</div><br/>

<div>
    <ul className="lisettt">
       
        <li>GUIDE PERMIS AFRICA</li><br/>
        <li>INFO ECOLE</li><br/>
        <li>GestAuto</li>
       

    </ul>
    </div>
</div>
</div>
<div className="copy">
    <br/>
Copyright © 2024 SEG.
</div>
      </div>
    )
}