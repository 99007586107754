
export const sidedataDir =[
    
    
    
    {
    title: 'Accueil',
    path: '/accueil2',
    cName: 'nav-text'
},
{
    title: 'Candidats',
    path: '/candidatDir',
    cName: 'nav-text'
},
{
    title: 'Depenses',
    path: '/depenseDir',
    cName: 'nav-text'
},

{
    title: 'Recettes',
    path: '/payementDir',
    cName: 'nav-text'
},

{
    title: 'Bilan Global',
    path: '/bilan',
    cName: 'nav-text'
},

{
    title: 'Presence',
    path: '/optPresenceDir',
    cName: 'nav-text'
},


{
    title: 'Resultats',
    path: '/resultatDir',
    cName: 'nav-text'
},

{
    title: 'Employés',
    path: '/employe',
    cName: 'nav-text'
},


]