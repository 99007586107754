import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
function Retrait(){
    const [loader, setLoader]= useState(false);
    const [dure, setDure]= useState('1')
    const [langue, setLangue]= useState('Français')
    const [payement, setPayement]= useState([])
    const [name, setName]= useState('')
    const [prenom, setPrenom]= useState('')
    const [total, setTotal]= useState('')
    const [contact, setContact]= useState('')
    const [sexe, setSexe]= useState('')
    const [categorie, setCategorie]= useState('')
    const [adresse, setAdresse]= useState('')
    const [age,setAge]= useState('')
    const [montant, setMontant]= useState('')
    const [resultatConduite, setResultatConduite]= useState('')
    const [statut, setStatut]= useState('')
    const [date, setDate]= useState('')
    const [id_ecole, setId_ecole]= useState('')
    const [dateRetrait, setDateRetrait]= useState('')
    const [retrait, setRetrait]= useState('non')
    const {id}= useParams();
    const [info, setInfo]= useState('')
    const [control, setControl]= useState('')
    const [dateControl, setDateControl]= useState('')
    const [sav,setSav]= useState('');
    const navigate = useNavigate();
    const [titre,setTitre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [out, setOut]= useState('')
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
   

   
    var date2= new Date();
        var autre= date2.toISOString().split('T')[0] 
        const [secretaire, setSecretaire] = useState([])
        useEffect(()=>{
            axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
            .then(res => setSecretaire(res.data))
            .catch(err => console.log(err));
        }, [])
 
    function handleSubmit(event){
        setLoader(true)
        setControl('')
            setInfo('')
        event.preventDefault();
        if(name=="" || prenom=="" || contact=="" || sexe=="" || montant==""  || adresse=="" || date==""|| total=="" || retrait=="" || dateRetrait=="" ){
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
            
         }else  if(contact.length<8){
            setControl('CONTACT INCORECTE')}
          else  if(date>autre){
                setDateControl('Verifier la date')
            }else if(montant<=0 || total<=0){
                setInfo('VEILLEZ VERIFIE LA VALEUR DU MONTANT')
             }
         else{

            

         
            axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/update0/'+id, {name,prenom,contact,sexe, adresse,montant,date,statut,total,retrait,dateRetrait,langue, dure,categorie}, { headers})
        .then(res => {
         
            if(secretaire.map((data)=>(data.poste))=='secretaire'){
                navigate('/pageCandidat/'+id);
              }else if(secretaire.map((data)=>(data.poste))=='directeur'){ navigate('/pageCandidatDir/'+id);}
           
        }).catch(err => console.log(err));
          

             
            
       
    }
    }
    
    const handleGoBack = () => {
        if(secretaire.map((data)=>(data.poste))=='secretaire'){
            navigate('/pageCandidat/'+id);
          }else if(secretaire.map((data)=>(data.poste))=='directeur'){ navigate('/pageCandidatDir/'+id);}
       
      };
    

    const [noCond,setNoCond]=useState ('')

    const [candidat, setCandidat] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
        .then(res => { 
            setName(res.data[0].nom)
            setPrenom(res.data[0].prenom)
            setContact(res.data[0].contact)
            setSexe(res.data[0].sexe)
            setAdresse(res.data[0].adresse)
            setAge(res.data[0].age)
            setId_ecole(res.data[0].id_ecole)
            setMontant(res.data[0].montant_payer)
            setStatut(res.data[0].statut_payement)
            setSav(res.data[0].statut_retrait_permis)
            setDate( moment(res.data[0].date).utc(1).format('YYYY-MM-DD'))
            setResultatConduite(res.data[0].activeConduite)
            setDure(res.data[0].dure)
            setLangue(res.data[0].langue)
            setOut('is')
            setCategorie(res.data[0].categorie)
            if(res.data[0].date_retrait==null){
                setDateRetrait(autre)
               
            }else{
               
                setDateRetrait( moment(res.data[0].date_retrait).utc(1).format('YYYY-MM-DD'))
            }
            
            setTotal(res.data[0].montant_total)
            setCandidat(res.data)})
        .catch(err => console.log(err));
    }, [])
    
 
    
  
    if(out=='is'){
   axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/paye',{name,prenom}, { headers})
   .then(res => setPayement(res.data)
 ).catch(err => console.log(err))
 setOut('')
}
 let totalPaye=0
 const first=montant
 
 
 
 payement.map((data, i)=> ( 
               
   totalPaye=totalPaye+data.montant    
 
 ))
   const paye= first+totalPaye

  useEffect(()=>{
  if(sav=='oui'){
    setRetrait('oui')
}
  })
    var inp
    if(retrait=='oui'){
        if(paye<total && resultatConduite!==1){

            inp=<h3 className="text-danger">
            "Attention candidat non admis et frais de formation non soldé"</h3>
           }
        else if(resultatConduite!==1){
            inp=<h3 className="text-danger">
            "Attention ce candidat n'est pas admis!!! " </h3>
           }else if(paye<total){
            inp=<h3 className="text-danger">
            "Attention ce candidat n'a pas soldé ses frais de formation!!! "</h3>
           } else{
            inp= <div className="libe">
            <label htmlFor="date" className="">DATE DE RETRAIT</label>
            <h4 className="text-danger">{dateControl}</h4>
             <input type="date"  name="date" className=""  id="date" onChange={e => setDateRetrait(e.target.value)}  value={dateRetrait}></input>
             
            </div>
           }
        
    }else {
        inp=''
    }


    let perm

    if(paye<total || resultatConduite!==1){
       perm= ''
    }else{
     perm=   <div className="look"> 
        <h2 className="lookT"> PERMIS RETIRE</h2>
        <div className="lookF">
        <div className="lookFF">
      
       <input type="radio" name="" className="" value="oui" checked={retrait=='oui'? true : false} onChange={e => setRetrait(e.target.value)}></input>
       <label className="">Oui</label>
       </div>
     <div className="lookFF">
      
       <input type="radio" className="" name="" value="non" checked={retrait=='non'? true: false} onChange={e => setRetrait(e.target.value)}></input>
       <label className="">Non</label>
       </div>
       </div>
        </div>
    }
    



    
    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }
  
  

    return(

         




<div className="authes">
<br/><br/><br/><br/>
            
            <body className="body">
            
            <div className="container"> 
            <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
            <h2 className="form-title">MISE A JOUR</h2>
                                 
                 <form action="#" onSubmit={handleSubmit}>
                 
                   <div className="hai">
                    <div className="user-input-box">
                 

                    <label htmlFor="fullName" className="">Nom</label>
                    
                    <input type="text" id="fullName" name="fullName" className="" value={name}   onChange={e => setName(e.target.value)} ></input>
                    </div>
                    <div className="user-input-box">
                    <label htmlFor="username" className="">Prenom</label>
                    <input type="text"  className="" name="username" id="username" value={prenom} onChange={e => setPrenom(e.target.value)}></input>
                    </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="contact" className="">Contact</label>
                    <h4 className="text-danger">{control}</h4>
                    <input type="number" name="contact" id="contact" className="" value={contact} onChange={e => setContact(e.target.value)}></input>
                    </div>
                   
                    <div className="user-input-box">
                    <label htmlFor="adresse" className="">Adresse</label>
                    <input type="text" name="adresse" className="" id="adresse" value={adresse} onChange={e => setAdresse(e.target.value)}></input>
                    
                    </div>
                    
                    <div className="user-input-box">
                    <label htmlFor="montantPayer" className="">Montant Payé</label>
                    <input type="number" name="montantPayer" className="" id="montantPayer" value={montant} onChange={e => setMontant(e.target.value)}></input>
                    
                    </div>
                    <div className="user-input-box">
                    <label htmlFor="fraisDeFormation" className="">Montant Total</label>
                    <input type="number" name="fraisDeFormation" className="" id="fraisDeFormation" value={total} onChange={e => setTotal(e.target.value)}></input>
                    
                    </div>
                    
                     
                     <div className="user-input-box">
                     <label htmlFor="date" className="">Date</label>
                     <h4 className="text-danger">{dateControl}</h4>
                    <input type="date" value={date} name="date" id="date" className="" onChange={e => setDate(e.target.value)}></input>
                     </div>

                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Langue</label>
                     <select name="langue" className='' onChange={e => setLangue(e.target.value)} >
                   
             
                    <option value={'ADJA'} selected={langue=='ADJA'? true:false}>ADJA</option>
                     <option value={'BARIBA'} selected={langue=='BARIBA'? true:false}>BARIBA</option>
                     <option value={'DINDI'} selected={langue=='DINDI'? true:false}>DINDI</option> 
                     <option value={'FON'} selected={langue=='FON'? true:false}>FON</option>
                     <option value={'Français'} selected={langue=='Français'? true:false}>Français</option>
                     <option value={'GOUN'} selected={langue=='GOUN'? true:false}>GOUN</option>
                     <option value={'MINA'} selected={langue=='MINA'? true:false}>MINA</option>
                     <option value={'YORUBA'}  selected={langue=='YORUBA'? true:false}>YORUBA</option>
                            
            </select>
                     </div>
                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Durée de la formation</label>
                     <select name="dure" className='' onChange={e => setDure(e.target.value)} >
                     <option value={'1'} selected={dure.charAt(0)=='1'? true:false}>1 Mois</option>
                    <option value={'2'} selected={dure.charAt(0)=='2'? true:false}>2 Mois</option>
                    <option value={'3'} selected={dure.charAt(0)=='3'? true:false}>3 Mois</option>
                    <option value={'4'} selected={dure.charAt(0)=='4'? true:false}>4 Mois</option>
                    <option value={'5'} selected={dure.charAt(0)=='5'? true:false}>5 Mois</option>
                    <option value={'6'} selected={dure.charAt(0)=='6'? true:false}>6 Mois</option>   
                    <option value={'12'} selected={dure.substring(0, 2)=='12'? true:false}>6 Mois</option>          
                     
            </select>
                     </div>
                     <div className="user-input-box">
                     <label htmlFor="langue" className="">Sexe</label>
                     <select name="sexe" className='' onChange={e => setSexe(e.target.value)} >
             
                         <option value={'Masculin'} selected={sexe=='Masculin'? true:false}>Masculin</option>
                         <option value={'Feminin'} selected={sexe=='Feminin'? true:false}>Feminin</option>
                                 
            </select>
                     </div>


                     </div>
                     
                     <div className="look"> 
                     <div className="lookTZ"> Catégorie</div>
                     <div className="lookFZ">
                    
                     <select name="categorie" className='' onChange={e => setCategorie(e.target.value)} >
                     <option value={''} >Selectionner</option>
                     <option value={'A'} selected={categorie=='A'? true:false}>Permis A</option>
             <option value={'A1'} selected={categorie=='A1'? true:false}>Permis A1</option>
             <option value={'A1+B'} selected={categorie=='A1+B'? true:false}>Permis A1+B</option>
             <option value={'A1+C+C1'} selected={categorie=='A1+C+C1'? true:false}>Permis A1+C+C1</option>
             <option value={'A3'} selected={categorie=='A3'? true:false}>Permis A3</option>
             <option value={'A3+A2'}  selected={categorie=='A3+A2'? true:false}>Permis A3+A2</option>
             <option value={'A1+A2+A3'} selected={categorie=='A1+A2+A3'? true:false}>Permis A1+A2+A3</option>
             <option value={'B'} selected={categorie=='B'? true:false}>Permis B</option>
             <option value={'C'} selected={categorie=='C'? true:false}>Permis C</option>
             <option value={'C1'} selected={categorie=='C1'? true:false}>Permis C1</option>
             <option value={'DR'} selected={categorie=='DR'? true:false}>Permis DR</option>
             <option value={'D'} selected={categorie=='D'? true:false}>Permis D</option>
             <option value={'E'} selected={categorie=='E'? true:false}>Permis E</option>
             <option value={'F'} selected={categorie=='F'? true:false}>Permis F</option>
                              
            </select>
           
                    </div>
                     </div>
                     
                     
                     <br/>
                    
{perm}
                     
                    {inp}
                 
                     <h4 className="text-danger">{info}</h4>
                    
                    <div class="form-submit-btn">
                    <input type="submit" className='' value="ENREGISTRER" ></input>
                    </div>
                    
                   
                    
                 </form>
                 
            </div> 
            </body>
            {load}
        </div>








       
    )
}
export default Retrait


