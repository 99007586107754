
import axios from "axios";
import React, {useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import Loader from "../components/loader";
import jsPDF from 'jspdf';
import moment from "moment";
import { Link, NavLink } from "react-router-dom";
import 'moment/locale/fr';
import 'jspdf-autotable';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
export default function StatDepenses() {
    
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      var date= new Date();
      const navigate = useNavigate();
      const years= date.getFullYear()
      var  month=  date.getMonth()+1
      const days= date.getDate()
      var datee= new Date(years,month-3,days)
      const  [listeDep, setListeDep]= useState([])
      const [date1, setDate1]= useState('')
      const [date2, setDate2]= useState('')
      const [select1,setSelect1]= useState(Cookies.get('select1'))
      const [select2,setSelect2]= useState(Cookies.get('select2'))
      const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
      const [id, setId]= useState('')
      const [loader, setLoader]= useState(false);
      const [error, setError] = useState(null);
   
      var autre2= datee.toISOString().split('T')[0] 
      var autre= date.toISOString().split('T')[0]


       
 useEffect(()=>{
    setLoader(true)
    if( select1 === undefined || select2== undefined){ 
    setDate1(autre2)
    setDate2(autre)
   }else if (select1 || select2){
     setDate1(select1)
     setDate2(select2)
   }
   setId(gestauto)

  }, [])
     
      

      useEffect(()=>{
        

        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/listeDep', {date1,date2,id},{headers})
      .then(res =>{ 
        if (Array.isArray(res.data)) {
            setListeDep(res.data);  // Inverse l'ordre des éléments si res.data est un tableau
          } else {
            console.error("Les données reçues ne sont pas un tableau : ", res.data);
            setListeDep([]);  // Définit un tableau vide si ce n'est pas un tableau
          }
        console.log('entout cas moi je suis là ', res.data)
        setLoader(false)
   
      }
      ).catch(err => {
        console.log(err)
      setError(err)
      setLoader(false)
    });
    }, [date1,date2,id])
   
    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }

    if(error){
        return (<div>Une erreur est survenue : {error.message}</div>)
    }else{
    return(
        
    <div className="authes">
        <br/> <br/> <br/>
         
        <body className="body">

        <div className="overlay"></div>
  <div className="fenetre-modal">
    <div className="modal-entete">
      <h3 className="titre-modal">Liste des dépenses</h3>
    </div>
    <div className="modal-corps">


    {
                      
                      listeDep.reverse().map((data, i)=> (
                          <div className="bilan-depense">
                              <span className='texte-depense'>
                          <span className="montant">{data.montant} FCFA</span><span className='txt'>
                          pour
                          </span>
                           <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                           </span>
                     
                           
                        </div>
                      ))
                    }

    </div>
    <div className="boutons-modal">
      <Link to={'/accueil'}>
        <button className="bouton-annuler">QUITTER</button>
      </Link>
    </div>
  </div>
     </body>

    </div>
    )
}
}