import React, {useEffect, useState } from "react"
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";


import moment from "moment";

export default function Prese(){

         const [query, setQuery]= useState(""); 
         const headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
            'credentials': 'include',
          };
         const [le, setLe]= useState('')
         const [name, setName]= useState('')
         const [prenom, setPrenom]= useState('')
         const [total, setTotal]= useState('')
         const [contact, setContact]= useState('')
         const [sexe, setSexe]= useState('')
         const [adresse, setAdresse]= useState('')
         const [age,setAge]= useState('')
         const [montant, setMontant]= useState('')
         const [statut, setStatut]= useState('')
         const [date, setDate]= useState('')
         const [nombre, setNombre]= useState([])
         const [nombre2, setNombre2]= useState([])
         const {id}= useParams();
         const [loginStatut, setLoginStatut]= useState('')
         const navigate = useNavigate();
         const [payement, setPayement]= useState([])
         
         
        
     
         
              
         function handleSubmit(event){
             event.preventDefault();
             
             
         }
         
         
     
     
     
         const [candidat, setCandidat] = useState([])
         useEffect(()=>{
             axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers })
             .then(res => { 
                 setLe(res.data[0].id)
                 setName(res.data[0].nom)
                 setPrenom(res.data[0].prenom)
                 setContact(res.data[0].contact)
                 setSexe(res.data[0].sexe)
                 setAdresse(res.data[0].adresse)
                 setAge(res.data[0].age)
                 setMontant(res.data[0].montant_payer)
                 setStatut(res.data[0].statut_payement)
                 setDate(res.data[0].date)
                 setCandidat(res.data)
                 setTotal(res.data[0].montant_total)
             
             }
                 
                 
                 
            
             
                
     
     
                 
                 )
             .catch(err => console.log(err));
     
         
         }, [])
         
         axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/paye',{name,prenom}, { headers })
         .then(res => setPayement(res.data)
     ).catch(err => console.log(err))
         
     let totalPaye=0
     const first=montant
     
     
     
     payement.map((data, i)=> ( 
                     
         totalPaye=totalPaye+data.montant    
     
     ))
        
         
        
     
     
        
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre',{le}, { headers })
        .then(res => setNombre(res.data)
     ).catch(err => console.log(err))
       
     axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nombre2',{le}, { headers })
        .then(res => setNombre2(res.data)
     ).catch(err => console.log(err))









    
    return(


        <>
    
   <div className="bian">
   <div>
   
                 <table className='taaa'>
         <thead>
            <tr>
            <th>Presence cour de Conduite : {nombre2.length}</th>                    
              </tr>
            </thead>
         
              {  
                nombre2.map((data, i)=> ( 
                    <tr>        
                        <td className='tbilan'>{moment(data.date).utc(1).format('DD-MM-YYYY')}</td>                                              
                    </tr>     
                ))
              }  
     </table>
     </div>
     <div>
    
     <table className='taaa'>
         <thead>
            <tr>
            <th> Presence cour de Code : {nombre.length}</th>                    
              </tr>
            </thead>
         
              {  
                nombre.map((data, i)=> ( 
                    <tr>        
                        <td className='tbilan'>{moment(data.date).utc(1).format('DD-MM-YYYY')}</td>                                              
                    </tr>     
                ))
              }  
     </table>
     </div>
     </div>
        </>
    )
}