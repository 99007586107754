import React, { useEffect } from 'react';

const Toast = ({ message, onClose, duration }) => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timeout);
  }, [onClose, duration]);

  return (
    <div className="toast">
      <div className="toast-content">{message}</div>
    </div>
  );
};

export default Toast;