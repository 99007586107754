import { Link } from "react-router-dom";
import Navbar from 'F:/projet/first/src/components/navbar';
import Footer from "../components/footer";
export default function MONI() {
    return(
     
  <>      
<div className='wini'>
        <Navbar/>
        <h1 className="win">La page de gestion des resultats</h1>
     <div className='buto'>

        <Link to={'/moniResult'}> <button className='red'> RESULTAT</button></Link>
        <Link to={'/oldCandidat'}> <button className='redmi'> ANCIENS CANDIDATS</button></Link>

        </div>

    </div>
    <Footer/>
    </>
    )


    
}