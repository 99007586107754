import React, {useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Navbar from 'F:/projet/first/src/components/navbar';
import Loader from "../components/loader";
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
function LesObservations(){
    const [name, setName]= useState('')
    
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
      const navigate= useNavigate()
      const [loader, setLoader]= useState(false);
    const [observation, setObservation]= useState('')
    const [poste, setPoste]= useState('')
    const {id}= useParams();
    const [affichage, setAffichage]= useState([]);
    const [info, setInfo]= useState('');
    var date= new Date();
   const years= date.getFullYear()
  const  month= date.getMonth()+1
   const day= date.getDate()
   const [titre,setTitre]= useState(Cookies.get('titre'))
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [secretaire, setSecretaire] = useState([])
  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
      .then(res => {
        setSecretaire(res.data)
        setName(res.data[0].nom)
        setPoste(res.data[0].poste)
        setInfo(res.data[0].id)
    })
      .catch(err => console.log(err));
  }, [])  
    function handleSubmit(event){
        event.preventDefault();
        setLoader(true)
        if(observation!=''){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/createObservation/'+id, {observation,years,month,day,name,poste}, { headers})
        .then(res => {
            setLoader(false)
            window.location.reload();
        }).catch(err => console.log(err));
    }
    }


    if(info!=''){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/affObservation',{id}, { headers})
                .then(res =>{ 
                    setAffichage(res.data)
            
                })
                .catch(err => console.log(err));
                setInfo('')

            }
            
       const [nameCand,setNameCand] = useState('')
       const [prenomCand, setPrenomCand]= useState('')
  
  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
      .then(res => { 
          
          setNameCand(res.data[0].nom)
          setPrenomCand(res.data[0].prenom)
       
      })
      .catch(err => console.log(err));
  }, []) 

  const handleGoBack = () => {
    navigate('/pageCandidat/'+id);
  };
  
  let load

  if(loader==true){
      load=   <div className="modal">
          <div className="overlay">

          </div>
             <div className="modal-contentis">
  <div className="anous">
  <Loader/>
  </div>
  </div>
      </div>
     
  }else{
      load=''
  }

    return(
        <div className="auths">
           <Navbar/>  
          
        
           <br/><br/><br/>
        <div className="containers"> 
        <FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
        <h1 className="form-title">OBSERVATION</h1>
        <h2 className="candName"> {nameCand} {prenomCand} </h2>
     <div className="">
   {
        affichage.map((data, i)=> (
                   <div>
                            <div className={data.nom_auteur== name ? 'affOa':'affOb'}> <div className="boite">
                           <div className="tete">   <h6 className="hh">{data.auteur} {data.nom_auteur} <br/></h6> <h6 className="hh">{moment(data.date).utc(1).format('DD-MM-YYYY')}</h6> </div>
                                
                                  {data.observation}</div>
                            
                            </div><br/><br/></div>
                        ))
                    }
                    </div>
        <br/><br/>
        
       <div className="position">
        <textarea cols={80} rows={3} className="obe" onChange={e => setObservation(e.target.value)} ></textarea>
        <button className="enr" onClick={handleSubmit}>ENREGISTRER</button>
        </div>
        </div>
        {load}
       </div>
    )
}
export default LesObservations