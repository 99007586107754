import Navbar from 'F:/projet/first/src/components/navbar';
import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Suppr from './supp.png'
import Mod from './mod.png'
import croix from './croix.png'
import { Link, NavLink, useNavigate } from "react-router-dom";
import moment from "moment";
import Footer from '../components/footer';
import { act } from '@testing-library/react';
import Cookies from 'js-cookie'
import Loader from "../components/loader";
import 'moment/locale/fr';
export default function Depense(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const navigate = useNavigate();

    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
  var  month=  date.getMonth()+1
  const [pop, setPop]= useState('')
 const [view, setView]= useState('')
  const day= date.getDate()
  const days= date.getDate()
  var datee= new Date(years,month-2,days)
  var autre2= datee.toISOString().split('T')[0] 
  var autre= date.toISOString().split('T')[0]
  const [loader, setLoader]= useState(true)
  const [error, setError] = useState(null);
  const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
  const [viewLoader, setViewLoader] = useState(false)
  const [secretaire, setSecretaire] = useState([])
  let lo

  const [select1,setSelect1]= useState(Cookies.get('select1'))
  const [select2,setSelect2]= useState(Cookies.get('select2'))


  const mettreAJourLargeurEcran = () => {
           setLargeurEcran(window.innerWidth);
       
         };


  const[value,setValue]= useState('')
  const [date1, setDate1]= useState('')
  const [date2, setDate2]= useState('')
  const [titre,setTitre]= useState(Cookies.get('titre'))
  const [vtoken, setVtoken]= useState(Cookies.get('token'))
  const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
  const [mie, setMie]= useState(Cookies.get('mie'))
  useEffect(()=>{
    setDate1(autre2)
    setDate2(autre)
    
}, [])

    const [depense, setDepense] = useState([])




    useEffect(()=>{
        
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers })
        .then(res => setSecretaire(res.data))
        .catch(err => console.log(err));
    }, [])
    


    useEffect(()=>{
       
           if(select1=== undefined || select2===undefined){
            let date1 = autre2
            let date2 = autre
            console.log('les date', date1, date2)
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/secreDepParDef',{date1,date2,gestauto,mie}, { headers})
        .then(res =>{ 
            setDepense(res.data)
            setLoader(false)
        })
        .catch(err => {
            console.log(err)
            setError(err)
            setLoader(false)
        });
           }else if(select1 || select2){
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/secreVvoirDep', {select1,select2,gestauto,mie}, { headers})
            .then(res => {
                setDate1(select1)
                setDate2(select2)

               setDepense(res.data)
               setLoader(false)
        
                console.log(res);
                
                
            }).catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                
                });
           }
    }, [])

    const handleDelete = async(id) => {
        try {
         await axios.delete('https://gestauto-56bfcbe4b876.herokuapp.com/depense/'+id, { headers})
         window.location.reload()
        }catch(err) {
         console.log(err);
        }
      }

      

      const [query, setQuery]= useState(""); 
  
      var mois1;

       var mois3;
       
       if(month==1){
        mois1=12
       }else{
        mois1=month-1
       };
       var mois2;
       if(month==2){
        mois2=12
       }else if(month==1) {
         mois2=11
       }else{
        mois2=month-2
       }
       console.log('mois1 rt 2',mois1,mois2)



        
        var mois 
        if(month==1){
            mois="Janvier"
        }else if(month==2){
            mois="Fevrier"
        }else if(month==3){
            mois="Mars"
        }else if(month==4){
            mois="Avril"
        }else if(month==5){
            mois="Mai"
        }else if(month==6){
            mois="Juin"
        }else if(month==7){
            mois="Juillet"
        }else if(month==8){
            mois="Août"
        }else if(month==9){
            mois="Septembre"
        }else if(month==10){
            mois="Octobre"
        }else if(month==11){
            mois="Novembre"
        }else if(month==12){
            mois="Decembre"
        }



        
        var date= new Date();
   

        function handleTout(event){
          
            event.preventDefault();
            setViewLoader(true)
            if(value=='Tous les depenses'){
                
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/tout', {gestauto,mie}, { headers})
                .then(res => {
                    setDepense(res.data)
                    setLoader(false)
                    setViewLoader(false)
                })
                .catch(err => {
                    console.log(err)
                    setError(err)
                    setLoader(false)
                    setViewLoader(false)
                });
                }
            else if(value=='Ce mois'){
                
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depense',{month,years,gestauto,mie}, { headers})
                .then(res => {
                    setDepense(res.data)
                    setLoader(false)
                    setViewLoader(false)
                })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                     setLoader(false)
                     setViewLoader(false)
                    });
            }else if(value=='Cette année'){
                
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/annee',{month,years,gestauto,mie}, { headers})
                .then(res =>{
                     setDepense(res.data)
                     setLoader(false)
                     setViewLoader(false)
                    })
                .catch(err =>{ console.log(err)
                 setError(err)
                 setLoader(false)
                 setViewLoader(false)   
                });
            }else{
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/depense',{month,years,gestauto,mie}, { headers})
                .then(res => {
                    setDepense(res.data)
                    setLoader(false)
                    setViewLoader(false)
                })
                .catch(err =>{
                     console.log(err)
                     setError(err)
                     setLoader(false)
                     setViewLoader(false)
                    });
            }
           
        }
         
        function handlePropose(event){
            setViewLoader(true)

            Cookies.set('select1',date1)
            Cookies.set('select2',date2)

            event.preventDefault();
            axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/person', {date1,date2,gestauto,mie}, { headers})
            .then(res => {
               setDepense(res.data)
               setLoader(false)
               setViewLoader(false)
                console.log(res);
                
                
            }).catch(err =>{
                 console.log(err)
                 setError(err)
                 setLoader(false)
                 setViewLoader(false)
                });
        }
       var dateElement= depense.map((data)=>(moment(data.date).utc(1).format('DD-MM-YYYY')))
       var action
       if(dateElement!=autre){
         action=''
       }else{
         action= <>
         <Link> <img src={Suppr} className='mod' onClick={e => handleDelete(depense.map((data)=>(data.id)))}/> </Link>
                                
                                <Link to={'/updateDepense/'+ depense.map((data)=>(data.id))} >  <img src={Mod} className='mod'/></Link>  
        </>
}

const[modal, setModal]= useState(false) 
const toggleModal = ()=> {
    setModal(!modal)
}

useEffect(() => {
        
    window.addEventListener('resize', mettreAJourLargeurEcran);

   
    return () => {
      window.removeEventListener('resize', mettreAJourLargeurEcran);
      
    };
   
  }, []);

  const[inf, setInf] = useState(false)
const[dataLibe, setDataLibe]= useState('')
const[dataDate, setDataDate]= useState('')
const[dataMontant, setDataMontant]= useState('')


const togglePop =()=> {
    setInf(!inf)
}
let varDate, varMontant ,  varLibe
if(pop!= ''){
   
lo= true
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vivoo', {pop}, { headers})
    .then(res => {
      setDataLibe(res.data[0].libelle)
      setDataDate(res.data[0].date)
      setDataMontant(res.data[0].montant)
     
       
        togglePop()
        setPop('')
        lo= false
      
      
    }).catch(err =>{
         console.log(err)
         setError(err)
         lo= false
    });
  
   
}


  if(view!= ''){
    setViewLoader(true)
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/vev', {years,month,day,view}, { headers})
    .then(res => {
       if(res.data.message== 'No match'){
        toggleModal()
        setViewLoader(false)
            setView('')
       }else{
        setViewLoader(false)
        navigate('/updateDepense/'+res.data[0].id)
       }
      
    }).catch(err => console.log(err));
    setView('')
  }     

  let totalDep=0


  depense.map((data, i)=> (
    totalDep=totalDep+data.montant
))
let load

if(viewLoader==true || lo== true){
    load=   <div className="avt">
    <div className="rin">
      <div className="chm">
        <Loader/>
      </div>
    </div>
  </div>
}else{
    load=''
}


if(loader){
    return(
        <div className="per">
        
        <div className="loader">
        <div className="overlayLo"> 
            <div className="loaderP">
            <Loader/>
            </div>
            </div>
            </div>
            </div>
    )
}else if(error){
    return (<div>Une erreur est survenue : {error.message}</div>)
}else{

    if(largeurEcran>= 1024){
    return(
        <>
    <div className='wini'>
        <Navbar/>

        <h1 className='win'>Les depenses du mois de {mois} </h1><br/>
{load}
        <div className='inove'>
        <div className="search">
             <input type="text" className="recherche" name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e=>setQuery(e.target.value)}></input>
        </div>

          

        <div className='selvil'  >
             
             <select name="poste" className='sel' onChange={e => setValue(e.target.value)} >
            <option value={'Ce mois'}>Ce mois</option>
            <option value={'Cette année'}>Cette année</option>
            <option value={'Tous les depenses'}>Tous les depenses</option>
            

                     
                
           </select>  
           <button className='butonSF2' onClick={handleTout}>Appliquer</button>
           <br/><br/>
          <h4 className='che'> Recherche personnalisée </h4>
           <div className='person'>
           <input type='date' value={date1} className='oix' onChange={e => setDate1(e.target.value)}></input> <input type='date' value={date2} className='oix' onChange={e => setDate2(e.target.value)} ></input> 
           <button type='submit' onClick={handlePropose} className='butonSF3'>APPLIQUER</button>
           </div>
           </div>

           </div>




        <div className=''>
        <div className=''>
             <Link to='/ajout' className='yello'><button className='ajouter'> AJOUTER UNE DEPENSE</button></Link><br/><br/>
         
             {secretaire.some(data => data.manage_registrations === 1) ? (
                <div>
         
          <div className='uncard-list'>
          {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifier2"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        
          </div>
             <div className='vT'>
             TOTAL : {totalDep} FCFA
             </div>
             </div>
             ) : (
                <div className="message-erreur">
                    Vous n'avez pas la permission d'acceder à la liste des dépenses.
                </div>
            )}
        </div>
        
    </div>
    <br/>
    </div>
    <Footer/>
    </>
    )
     }
     else if(largeurEcran>=510){
        return(
            <>
            <div className='wini'>
            <Navbar/>
    
            <h1 className='win'>Les depenses du mois de {mois} </h1><br/>
    {load}
            <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>

{/* Barre de sélection avec bouton Appliquer */}
<div className='barChoixT'>
    <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
    <option >Ce mois</option>
            <option>Cette année</option>
            <option >Tous les depenses</option>
    </select>
    {/* Bouton Appliquer pour le select */}
    <button className='butonSF' onClick={handleTout}>Appliquer</button>
</div>

{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>
    
    
    
            <div className=''>
            <div className=''>
                 <Link to='/ajout' className='yello'><button className='ajouter'> AJOUTER UNE DEPENSE</button></Link><br/><br/>
               
               
                 {secretaire.some(data => data.manage_registrations === 1) ? (
                <div>      
<div className='card-list'>

                        {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifie"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        
 
        </div>
               
               
                 <div className='vT'>
                 TOTAL : {totalDep} FCFA
                 </div>
                 </div>
             ) : (
                <div className="message-erreur">
                    Vous n'avez pas la permission d'acceder à la liste des dépenses.
                </div>
            )}
            </div>
            
        </div>
        <br/>
        </div>
        <Footer/>
        </>
         )
     }
     else{
        return(
            <>
            <div className='wini'>
            <Navbar/>
    
            <h1 className='win'>Les depenses du mois de {mois} </h1><br/>
    {load}
            <div className='barContener'>

{/* Barre de recherche */}
<div className='barChercheT'>
    <input type="text"className='rechBar'  name="searchBar" id="searchBar" placeholder="Rechercher" onChange={e => setQuery(e.target.value)}></input>
</div>

{/* Barre de sélection avec bouton Appliquer */}
<div className='barChoixT'>
    <select name="poste" className='choosee' onChange={e => setValue(e.target.value)}>
    <option >Ce mois</option>
            <option>Cette année</option>
            <option >Tous les depenses</option>
    </select>
    {/* Bouton Appliquer pour le select */}
    <button className='butonSF' onClick={handleTout}>Appliquer</button>
</div>

{/* Barre de recherche personnalisée */}
<div className='persBarT'>
    
    <input type='date' className='perSF' value={date1} onChange={e => setDate1(e.target.value)}></input>
    <input type='date' className='perSF' value={date2} onChange={e => setDate2(e.target.value)}></input>
    <button type='submit' className='butonSF' onClick={handlePropose}>APPLIQUER</button>
</div>

</div>
    
    
    
            <div className=''>
            <div className=''>
                 <Link to='/ajout' className='yello'><button className='ajouter'> AJOUTER UNE DEPENSE</button></Link><br/><br/>
               
               
           
                 {secretaire.some(data => data.manage_registrations === 1) ? (
                <div>

                        {
                        
                        depense.filter((data) => data.date.toLowerCase().includes(query) || data.libelle.toLowerCase().includes(query)).reverse().map((data, i)=> (
                            <div className="bilan-depense">
                                <span className='texte-depense'>
                            <span className="montant">{data.montant} FCFA</span><span className='txt'>
                            pour
                            </span>
                             <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                             </span>
                             <Link onClick={e => setView(data.id)}  >  <span className="icone-modifie"> </span></Link> 
                               {modal && (<div className='modal'>
    <div className='overlay'>
        
    </div>
    <div className='modal-content'>
        <h2>Attention cette depence ne peut plus être modifiée!!!</h2>
      <Link onClick={toggleModal}>  <img src={croix} className='close-modal'/></Link>
    </div>
</div>)}
                             
                          </div>
                        ))
                      }
        
 
        
               
               
                 <div className='vT'>
                 TOTAL : {totalDep} FCFA
                 </div>
                 </div>
             ) : (
                <div className="message-erreur">
                    Vous n'avez pas la permission d'acceder à la liste des dépenses.
                </div>
            )}
            </div>
            
        </div>
        <br/>
        </div>
        <Footer/>
        </>
         )
     }
}
}

