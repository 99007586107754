import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import Navbar from 'F:/projet/first/src/components/navbar';
import Footer from '../components/footer';
import moni from 'F:/projet/first/src/components/image/moni.png';
import secre from 'F:/projet/first/src/components/image/images.png'
import { useNavigate } from "react-router-dom";
import Loader from "../components/loader";
import Cookies from 'js-cookie';
export default function AjoutPresence(){
    const [candidat, setCandidat] = useState([])
    const [presence,setPresence]= useState([])
    
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    
    const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))
    const [ident, setIdent]= useState('')
    const [etat, setEtat]= useState('')
    const navigate = useNavigate();
    const [mie, setMie]= useState(Cookies.get('mie'))
    var date= new Date();
    const now= date.toLocaleDateString();
   const years= date.getFullYear()
  const  month= date.getMonth()+1
   const day= date.getDate()   
   const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);
   const [error, setError] = useState(null);
   const [loader, setLoader]= useState(true)
   const [viewLoader, setViewLoader] = useState(false)    
   const [enabledSettings, setEnabledSettings] = useState([]);
    const [secretaire, setSecretaire] = useState([])
    useEffect(()=>{
       
    }, [])
       

    useEffect(()=>{

      

        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/moniInfo?id='+gestauto+'', { headers})
        .then(res =>{
             setSecretaire(res.data)
             setIdent(res.data[0].id_ecole)
            })
        .catch(err => console.log(err));

   

    }, []) 
    if(ident!=''){
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/mise', {years,month,day,ident}, { headers})
        .then(res => {
           setCandidat(res.data)
           setLoader(false)
            
        }).catch(err =>{
             console.log(err)
             setError(err)
             setLoader(false)
            });
        setIdent('')
    }
    const [id_ecole]= mie;

     const [query, setQuery]= useState(""); 
     
         let exp

    
        var checkboxes = document.querySelectorAll("input[type=checkbox][name=settings]");
        const handleCheckboxChange = (candidateId) => {
            setEnabledSettings((prevEnabledSettings) => {
              if (prevEnabledSettings.includes(candidateId)) {
                // Si le candidat est déjà dans enabledSettings, le retire
                return prevEnabledSettings.filter((id) => id !== candidateId);
              } else {
                // Sinon, l'ajoute
                return [...prevEnabledSettings, candidateId];
              }
            });
          };

        const mettreAJourLargeurEcran = () => {
            setLargeurEcran(window.innerWidth);
        
          };
        
           
           
            
            
            useEffect(() => {      
                window.addEventListener('resize', mettreAJourLargeurEcran);      
                return () => {
                  window.removeEventListener('resize', mettreAJourLargeurEcran);        
                };      
              }, []);


       
    
            function handleSubmit(event){
                setViewLoader(true)
                event.preventDefault();
                console.log('please2',exp)
                var heureCourante = date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
                axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/exp', {enabledSettings,now,years,month,day,id_ecole,heureCourante}, { headers})
                .then(res => {

                   setViewLoader(false)
                    
                }).catch(err => {
                    console.log(err)
                    setError(err)
                    setViewLoader(false)
                }); 
               
                navigate('/voirCond')
            }




            let load

            if(viewLoader==true){
                load=<div className="avt">
                <div className="rin">
                  <div className="chm">
                    <Loader/>
                  </div>
                </div>
              </div>
                    
            }else{
                load=''
            }


            if(loader){
                return(
                    <div className="per">
                    
                    <div className="loader">
                    <div className="overlayLo"> 
                        <div className="loaderP">
                        <Loader/>
                        </div>
                        </div>
                        </div>
                        </div>
                )
            }else if(error){
                return (<div>Une erreur est survenue : {error.message}</div>)
            }

            else{
                if(largeurEcran>= 510){  
return(
    <>
                
                <div className='wini'>
                <Navbar/>
                <h1 className='win'> Liste des non présents du cour de {now}</h1>
                <br/><br/>
        
               {load}
            <div className=''>
                <div className=''>
                    
                <div className="card-list"  >
                                 {
                                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                                <div className="card" onClick={() => handleCheckboxChange(data.id)}>
                                                           <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                                            
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                <p className='contacts'> {data.contact}</p>
                                               
                                                <input type='checkbox' value={data.id} className='checko' name='settings' checked={enabledSettings.includes(data.id)}
    ></input>
                
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                </div>
                <h3 className='pesen'>{etat}</h3>
              <button type="submit" className='enregistrercond' onClick={handleSubmit} >ENREGISTRER</button> 
        
            </div>
            
            </div>
            <Footer/>
            </>
)
                            }
                else{
                    return(
                        <>
                                    
                                    <div className='wini'>
                                    <Navbar/>
                                    <h1 className='win'> Liste des non présents du cour de {now}</h1>
                                    <br/><br/>
                            
                                   {load}
                                <div className=''>
                                <div className="card-list"  >
                                 {
                                            candidat.filter((data) => data.nom.toLowerCase().includes(query) || data.prenom.toLowerCase().includes(query)).map((data, i)=> (
                                                <div className="card" onClick={() => handleCheckboxChange(data.id)}>
                                                            <div className="image-container8">
                 <img src={data.image ? `${data.image}` : (data.sexe === 'Masculin' ? moni : secre)} alt="Candidat" className="profile-image" />

         
          </div>
                                          
                                             <div className='info'>
                                                <p className='name'> {data.nom} {data.prenom}</p>
                                             
                                                <p className='contacts'> {data.contact}</p>
                                               
                                                <input type='checkbox' value={data.id} className='checko' name='settings' checked={enabledSettings.includes(data.id)}
    ></input>
                
                                                </div>
                                            </div>
                                        
                                            ))
                                          } 
                                 </div>
                
                                    <h3 className='pesen'>{etat}</h3>
                                  <button type="submit" className='enregistrercond' onClick={handleSubmit} >ENREGISTRER</button> 
                            
                                </div>
                                
                                </div>
                                <Footer/>
                                </>
                    )   
                }
}
                            }