import Navbar from 'F:/projet/first/src/components/navbar';
import React,{  useEffect, useState, useRef} from 'react';
import axios from 'axios';
import Footer from '../components/footer';
import Cookies from 'js-cookie';
import Chart from 'chart.js/auto';
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "../components/loader";
import 'moment/locale/fr';





// les dates pour les filtres
var date= new Date();
const years= date.getFullYear()
var  month=  date.getMonth()+1
const days= date.getDate()
var datee= new Date(years,month-2,days)

export default function Accueil() {


  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };

  //cookies
  const [titre,setTitre]= useState(Cookies.get('titre'))
  const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))

//user info
  const [secretaire, setSecretaire] = useState([])
  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', { headers})
      .then(res => setSecretaire(res.data))
      .catch(err => console.log(err));
  }, [])

//end



  return(
    <>
 
      <SecondDesign />
    

</>
  )
   
}


const SecondDesign = () => {

  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
    'credentials': 'include',
  };

  var autre2= datee.toISOString().split('T')[0] 
  var autre= date.toISOString().split('T')[0]


// les états
  const [select1,setSelect1]= useState(Cookies.get('select1'))
  const [select2,setSelect2]= useState(Cookies.get('select2'))
  const [titre,setTitre]= useState(Cookies.get('titre'))
  const [vtoken, setVtoken]= useState(Cookies.get('token'))
  const [gestauto,setGestauto]= useState(Cookies.get('gestauto'))

  const [date1, setDate1]= useState('')
  
  const [date2, setDate2]= useState('')


  // informations user
  const [secretaire, setSecretaire] = useState([])

  useEffect(()=>{
      axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/navbar?id='+gestauto+'&titre='+titre+'', {headers})
      .then(res => setSecretaire(res.data))
      .catch(err => console.log(err));
 }, [])



  
 // mise à jour de la valeur de des dates selectionné sur la page dans les cookies
 useEffect(()=>{
  if( select1 === undefined || select2== undefined){ 
  setDate1(autre2)
  setDate2(autre)
 }else if (select1 || select2){
   setDate1(select1)
   setDate2(select2)
 }
}, [])



//variables important
const [labels, setLabels]= useState('')
const [depense, setDepense]= useState('')
const [depenseTotal, setDepenseTotal]= useState('')
const [paiement, setPaiement]= useState('')
const [paiementTotal, setPaiementTotal]= useState('')
const [inscrit, setInscrit]= useState([])
const [inscritTotal, setInscritTotal]= useState([])
const [permis, setPermis]= useState('')
const [stop, setStop] =useState('')
const [nbrAd, setNbrAd]= useState('')
const [nbrNad, setNbrNad]= useState('')
const [stop2, setStop2] =useState('')
const [lesI, setLesI] = useState('')
const [lesAdm, setLesAdm] = useState('')
const [lesNdm, setLesNdm] = useState('')
const  [listeDep, setListeDep]= useState('')
const  [listePay, setListePay]= useState('')
const  [listePay2, setListePay2]= useState('')
const  [listeRt, setListeRt]= useState('')
const [viewLoader, setViewLoader] = useState(false)
const [error, setError] = useState(null);
const id= secretaire.map((data)=>(data.id_ecole))

// end



//requettes pour les stats

 if(date1!=='' && date2!=='' && stop===''&& id){
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/countDash', {date1,date2,id},{headers})
  .then(res =>{  
    const counts = res.data.map(item => item.count); 
    // Extraction des valeurs 'count' uniquement
    setInscritTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setInscrit(counts);
    setStop('oui')
  }
  ).catch(err => console.log(err));


  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nbrAd', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrAd(res.data[0].count)
  }
  ).catch(err => console.log(err));

  
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nbrNad', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrNad(res.data[0].count)
  }
   
  ).catch(err => console.log(err));

  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/PermisRetire', {date1,date2,id},{headers})
  .then(res =>{ 
    setPermis(res.data[0].count)
  }
   

  ).catch(err => console.log(err));

}

if(date1!=='' && date2!=='' && stop2===''&& id){

  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/countPaie', {date1,date2,id},{headers})
  .then(res =>{ 
    const counts = res.data.map(item => item.count); 
    // Extraction des valeurs 'count' uniquement
    setPaiementTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setPaiement(counts);
      setStop2('oui') 
  }
   
  ).catch(err => console.log(err));


  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/countDepe', {date1,date2,id},{headers})
  .then(res =>{ 
    const counts = res.data.map(item => item.count);
     // Extraction des valeurs 'count' uniquement
    setDepenseTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setDepense(counts);
      setStop2('oui') 
  }
   
  ).catch(err => console.log(err));

}

//end requettes stat






// repartire les date en interval pour le graphe (ex: juin-juillet-Aout)

const intervalData = [];
let currentDate = new Date(date1);
while (currentDate <= new Date(date2)) {
  const month = currentDate.toLocaleString('default', { month: 'long' }); // Obtenez le nom complet du mois
  // Déterminez la date de début du mois
  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const startDateFormatted = startOfMonth.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

  // Déterminez la date de fin du mois
  const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
  const endDateFormatted = endOfMonth.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });

  // Ajoutez les dates de début et de fin à intervalData
  intervalData.push({ month: month, startDate: startDateFormatted, endDate: endDateFormatted });

  
  // Passez au mois suivant
  currentDate.setMonth(currentDate.getMonth() + 1);
  console.log('intervalle data ici et current date',intervalData, currentDate)
}



// dash code

const chartRef = useRef();
 let myChart = null;

 useEffect(() => {
  if (chartRef.current && secretaire.some(data => data.view_info === 1 && titre === "moniteur") || ((secretaire.some(data => data.manage_payments === 1) && 
  secretaire.some(data => data.manage_registrations === 1)) && titre === "secretaire")) {


   const ctx = chartRef.current.getContext('2d');

   // Exemple de données (à remplacer par vos propres données)
   
// Utilisez les données de l'intervalle pour générer les labels et les données du graphique
   setLabels( intervalData.map(monthData => monthData.month)); // Supposons que monthData soit un tableau d'objets avec les propriétés month, startDate et endDate
    const data = intervalData.map(monthData => monthData.value); // Supposons que value est la valeur de la statistique pour chaque mois
    



    
    // Mettez à jour l'état du graphique avec les nouvelles données
    const chartData=({
      labels: labels,
      datasets: [
        {
          label: "Nombre de candidat inscrits",
          data:inscrit,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          barThickness: 30, // Largeur fixe pour chaque barre
          maxBarThickness: 40, // Largeur maximale
          minBarLength: 10, // Longueur minimale
        }
      ]
    });
  

   const options = {
     scales: {
       y: {
        grid: {
          display: false, // Désactive la grille de l'axe X
      },
         beginAtZero: true,
         title: {
           display: true,
           text: "Nombre d'inscrits",
           color: '#000', // Couleur du titre
           font: {
             family: 'Arial',
             size: 14,
             weight: 'bold',
             lineHeight: 1.2
           },
           padding: { top: 10, left: 0, right: 0, bottom: 0 }
         },
         ticks: {
          stepSize: 1000, // Augmentez cette valeur pour réduire le nombre de ticks
          maxTicksLimit: 10, // Limitez le nombre maximum de ticks
        }
       },
       x: {
         title: {
          grid: {
            display: false, // Désactive la grille de l'axe X
        },
           display: true,
           text: 'Mois',
           color: '#000', // Couleur du titre
           font: {
             family: 'Arial',
             size: 14,
             weight: 'bold',
             lineHeight: 1.2
           },
           padding: { top: 10, left: 0, right: 0, bottom: 0 }
         }
       }
     },
     grid: {
      display: true,  // Afficher ou masquer la grille
      color: 'rgba(0, 0, 0, 0.1)', // Couleur des lignes de la grille
      lineWidth: 1, // Épaisseur des lignes
      drawBorder: true, // Dessiner les bords de l'axe
      borderDash: [5, 5], // Type de ligne (pointillée ici)
      zeroLineColor: '#000', // Couleur de la ligne zéro
      zeroLineWidth: 2, // Épaisseur de la ligne zéro
    },
    
    ticks: {
      display: true,  // Afficher ou masquer les étiquettes
      color: '#000', // Couleur des étiquettes
      font: {
        family: 'Arial',
        size: 12,
        weight: 'normal',
      },
      padding: 5, // Espace entre les étiquettes et l'axe
      stepSize: 10, // Intervalle entre les valeurs
      callback: function(value, index, values) {
        return value + ' €'; // Ajout d'un suffixe aux valeurs
      }
    },

    position: 'bottom',

    stacked: true,

    border: {
      display: true, // Afficher ou masquer la bordure
      color: '#000', // Couleur de la bordure
      width: 2 // Épaisseur de la bordure
    },
    responsive: true,

maintainAspectRatio: false,

plugins: {
  legend: {
    display: true, // Afficher ou masquer la légende
    position: 'top', // Position de la légende ('top', 'left', 'bottom', 'right')
    labels: {
      color: '#000', // Couleur du texte des légendes
      font: {
        family: 'Arial',
        size: 12,
        weight: 'bold',
      },
      boxWidth: 20, // Taille de la boîte de couleur à côté du texte
      padding: 10 // Espace entre les éléments de la légende
    }
  }
},

plugins: {
  tooltip: {
    enabled: true, // Afficher ou masquer les tooltips
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond
    titleFont: {
      family: 'Arial',
      size: 14,
      weight: 'bold',
    },
    bodyFont: {
      family: 'Arial',
      size: 12,
    },
    footerFont: {
      family: 'Arial',
      size: 10,
    },
    padding: 10, // Espace à l'intérieur du tooltip
    cornerRadius: 4, // Bord arrondi du tooltip
  }
},

animation: {
  duration: 1000, // Durée de l'animation en ms
  easing: 'easeInOutQuad', // Type d'animation
},

layout: {
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10
  }
},

backgroundColor: 'rgba(255, 255, 255, 1)',

elements: {
  bar: {
    backgroundColor: '#8884d8', // Couleur des barres
    borderRadius: 4, // Rayon des coins des barres
    borderWidth: 2, // Épaisseur des bordures des barres
  }
},

   };

   if (myChart) {
     myChart.destroy(); // Détruire le graphique existant si présent
   }

   myChart = new Chart(ctx, {
     type: 'bar',
     data: chartData,
     options: options
   });

   return () => {
     if (myChart) {
       myChart.destroy(); // Détruire le graphique lors du démontage du composant
     }
   };
  }
 }, [inscrit,secretaire,titre]);



 const chartRef2 = useRef();
 let myChart2 = null;


 useEffect(() => {
  if (chartRef2.current && secretaire.some(data => data.view_info === 1 && titre === "moniteur") || ((secretaire.some(data => data.manage_payments === 1) && 
  secretaire.some(data => data.manage_registrations === 1)) && titre === "secretaire")) {
  const ctx = chartRef2.current.getContext('2d');

  // Exemple de données (à remplacer par vos propres données)
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Revenus',
        data: paiement, // Remplacez ces données par vos propres données
        backgroundColor: '#007bff',
        borderColor: '#0056b3',
        borderWidth: 1,
        barThickness: 30, // Largeur fixe pour chaque barre
        maxBarThickness: 40, // Largeur maximale
        minBarLength: 10, // Longueur minimale
      },
      {
        label: 'Dépenses',
        data: depense, // Remplacez ces données par vos propres données
        backgroundColor: '#ffc107',
        borderColor: '#ffca28',
        borderWidth: 1,
        barThickness: 30, // Largeur fixe pour chaque barre
        maxBarThickness: 40, // Largeur maximale
        minBarLength: 10, // Longueur minimale
      }
    ]
  };

  const options = {
    scales: {
      y: {
        grid: {
          display: false, // Désactive la grille de l'axe X
      },
        beginAtZero: true,
        title: {
          display: true,
          text: 'Montant (en FCFA)',
          color: '#000', // Couleur du titre
          font: {
            family: 'Arial',
            size: 14,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: { top: 10, left: 0, right: 0, bottom: 0 }
        },
        ticks: {
          stepSize: 1000, // Augmentez cette valeur pour réduire le nombre de ticks
          maxTicksLimit: 10, // Limitez le nombre maximum de ticks
        }
      },
      x: {
        grid: {
          display: false, // Désactive la grille de l'axe X
      },
        title: {
     
          display: true,
          text: 'Mois',
          color: '#000', // Couleur du titre
          font: {
            family: 'Arial',
            size: 14,
            weight: 'bold',
            lineHeight: 1.2
          },
          padding: { top: 10, left: 0, right: 0, bottom: 0 }
        }
      }
    },
    grid: {
      display: true,  // Afficher ou masquer la grille
      color: 'rgba(0, 0, 0, 0.1)', // Couleur des lignes de la grille
      lineWidth: 1, // Épaisseur des lignes
      drawBorder: true, // Dessiner les bords de l'axe
      borderDash: [5, 5], // Type de ligne (pointillée ici)
      zeroLineColor: '#000', // Couleur de la ligne zéro
      zeroLineWidth: 2, // Épaisseur de la ligne zéro
    },
    
    ticks: {
      display: true,  // Afficher ou masquer les étiquettes
      color: '#000', // Couleur des étiquettes
      font: {
        family: 'Arial',
        size: 12,
        weight: 'normal',
      },
      padding: 5, // Espace entre les étiquettes et l'axe
      stepSize: 10, // Intervalle entre les valeurs
      callback: function(value, index, values) {
        return value + ' €'; // Ajout d'un suffixe aux valeurs
      }
    },

    position: 'bottom',

    stacked: true,

    border: {
      display: true, // Afficher ou masquer la bordure
      color: '#000', // Couleur de la bordure
      width: 2 // Épaisseur de la bordure
    },
    responsive: true,

maintainAspectRatio: false,

plugins: {
  legend: {
    display: true, // Afficher ou masquer la légende
    position: 'top', // Position de la légende ('top', 'left', 'bottom', 'right')
    labels: {
      color: '#000', // Couleur du texte des légendes
      font: {
        family: 'Arial',
        size: 12,
        weight: 'bold',
      },
      boxWidth: 20, // Taille de la boîte de couleur à côté du texte
      padding: 10 // Espace entre les éléments de la légende
    }
  }
},

plugins: {
  tooltip: {
    enabled: true, // Afficher ou masquer les tooltips
    backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond
    titleFont: {
      family: 'Arial',
      size: 14,
      weight: 'bold',
    },
    bodyFont: {
      family: 'Arial',
      size: 12,
    },
    footerFont: {
      family: 'Arial',
      size: 10,
    },
    padding: 10, // Espace à l'intérieur du tooltip
    cornerRadius: 4, // Bord arrondi du tooltip
  }
},

animation: {
  duration: 1000, // Durée de l'animation en ms
  easing: 'easeInOutQuad', // Type d'animation
},

layout: {
  padding: {
    top: 10,
    right: 10,
    bottom: 10,
    left: 10
  }
},

backgroundColor: 'rgba(255, 255, 255, 1)',

elements: {
  bar: {
    backgroundColor: '#8884d8', // Couleur des barres
    borderRadius: 4, // Rayon des coins des barres
    borderWidth: 2, // Épaisseur des bordures des barres
  }
},

  };

  if (myChart2) {
    myChart2.destroy(); // Détruire le graphique existant si présent
  }

  myChart2 = new Chart(ctx, {
    type: 'bar',
    data: data,
    options: options
  });

  return () => {
    if (myChart2) {
      myChart2.destroy(); // Détruire le graphique lors du démontage du composant
    }
  };
}
}, [paiement,labels,depense,secretaire,titre]);
//end


 
  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = (event) => {
    event.preventDefault();
    Cookies.set('select1',date1)
    Cookies.set('select2',date2)
    // Exécuter la requête Axios pour récupérer les données actualisées en fonction des nouvelles dates
    axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/countDash', { date1, date2,id },{headers})
      .then(res => {
        console.log('Nouvelles données:', res.data);
        const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
        console.log('Counts:', counts);
        setInscritTotal(counts.reduce((acc, curr)=> acc+curr,0))
        setInscrit(counts);
        setStop('oui');
      })
      .catch(err => console.log(err));

      axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/countPaie', {date1,date2,id},{headers})
      .then(res =>{ 
        console.log('paie',res.data);
        const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
        console.log('CountsPaie:', counts);
        setPaiementTotal(counts.reduce((acc, curr)=> acc+curr,0))
        setPaiement(counts);
          setStop2('oui') 
      }
       
      ).catch(err => console.log(err));

      
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/countDepe', {date1,date2,id},{headers})
  .then(res =>{ 
    console.log('depe',res.data);
    const counts = res.data.map(item => item.count); // Extraction des valeurs 'count' uniquement
    console.log('CountsDepe:', counts);
    setDepenseTotal(counts.reduce((acc, curr)=> acc+curr,0))
    setDepense(counts);
      setStop2('oui') 
  }
   
  ).catch(err => console.log(err));

  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nbrAd', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrAd(res.data[0].count)
  }
   
  ).catch(err => console.log(err));

  
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/nbrNad', {date1,date2,id},{headers})
  .then(res =>{ 
    setNbrNad(res.data[0].count)
  }
   

  ).catch(err => console.log(err));

  
  axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/PermisRetire', {date1,date2,id},{headers})
  .then(res =>{ 
    setPermis(res.data[0].count)
  }
   

  ).catch(err => console.log(err));


  };

  useEffect(() => {
    if (chartRef.current && secretaire.some(data => data.view_info === 1 && titre === "moniteur") || ((secretaire.some(data => data.manage_payments === 1) && 
    secretaire.some(data => data.manage_registrations === 1)) && titre === "secretaire")) {
    // Code pour initialiser le graphique au chargement du composant
    const ctx = chartRef.current.getContext('2d');
    const options = {
      scales: {
        y: {
          grid: {
            display: false, // Désactive la grille de l'axe X
        },
          beginAtZero: true,
          title: {
            display: true,
            text: "Nombre d'inscrits",
            color: '#000', // Couleur du titre
            font: {
              family: 'Arial',
              size: 14,
              weight: 'bold',
              lineHeight: 1.2
            },
            padding: { top: 10, left: 0, right: 0, bottom: 0 }
          }
        },
        x: {
          grid: {
            display: false, // Désactive la grille de l'axe X
        },
          title: {
            display: true,
            text: 'Mois',
            color: '#000', // Couleur du titre
            font: {
              family: 'Arial',
              size: 14,
              weight: 'bold',
              lineHeight: 1.2
            },
            padding: { top: 10, left: 0, right: 0, bottom: 0 }
          }
        }
      },
      grid: {
        display: true,  // Afficher ou masquer la grille
        color: 'rgba(0, 0, 0, 0.1)', // Couleur des lignes de la grille
        lineWidth: 1, // Épaisseur des lignes
        drawBorder: true, // Dessiner les bords de l'axe
        borderDash: [5, 5], // Type de ligne (pointillée ici)
        zeroLineColor: '#000', // Couleur de la ligne zéro
        zeroLineWidth: 2, // Épaisseur de la ligne zéro
      },
      
      ticks: {
        display: true,  // Afficher ou masquer les étiquettes
        color: '#000', // Couleur des étiquettes
        font: {
          family: 'Arial',
          size: 12,
          weight: 'normal',
        },
        padding: 5, // Espace entre les étiquettes et l'axe
        stepSize: 10, // Intervalle entre les valeurs
        callback: function(value, index, values) {
          return value + ' €'; // Ajout d'un suffixe aux valeurs
        }
      },
  
      position: 'bottom',
  
      stacked: true,
  
      border: {
        display: true, // Afficher ou masquer la bordure
        color: '#000', // Couleur de la bordure
        width: 2 // Épaisseur de la bordure
      },
      responsive: true,
  
  maintainAspectRatio: false,
  
  plugins: {
    legend: {
      display: true, // Afficher ou masquer la légende
      position: 'top', // Position de la légende ('top', 'left', 'bottom', 'right')
      labels: {
        color: '#000', // Couleur du texte des légendes
        font: {
          family: 'Arial',
          size: 12,
          weight: 'bold',
        },
        boxWidth: 20, // Taille de la boîte de couleur à côté du texte
        padding: 10 // Espace entre les éléments de la légende
      }
    }
  },
  
  plugins: {
    tooltip: {
      enabled: true, // Afficher ou masquer les tooltips
      backgroundColor: 'rgba(0, 0, 0, 0.7)', // Couleur de fond
      titleFont: {
        family: 'Arial',
        size: 14,
        weight: 'bold',
      },
      bodyFont: {
        family: 'Arial',
        size: 12,
      },
      footerFont: {
        family: 'Arial',
        size: 10,
      },
      padding: 10, // Espace à l'intérieur du tooltip
      cornerRadius: 4, // Bord arrondi du tooltip
    }
  },
  
  animation: {
    duration: 1000, // Durée de l'animation en ms
    easing: 'easeInOutQuad', // Type d'animation
  },
  
  layout: {
    padding: {
      top: 10,
      right: 10,
      bottom: 10,
      left: 10
    }
  },
  
  backgroundColor: 'rgba(255, 255, 255, 1)',
  
  elements: {
    bar: {
      backgroundColor: '#8884d8', // Couleur des barres
      borderRadius: 4, // Rayon des coins des barres
      borderWidth: 2, // Épaisseur des bordures des barres
    }
  },
  
    };

    if (myChart) {
      myChart.destroy(); // Détruire le graphique existant si présent
    }

    myChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            label: "Nombre de candidat inscrits",
            data: inscrit,
            backgroundColor: '#28a745',
            borderColor: '#1e7e34',
            borderWidth: 1,
            barThickness: 30, // Largeur fixe pour chaque barre
            maxBarThickness: 40, // Largeur maximale
            minBarLength: 10, // Longueur minimale
          }
        ]
      },
      options: options
    });

    return () => {
      if (myChart) {
        myChart.destroy(); // Détruire le graphique lors du démontage du composant
      }
    };
  }
  }, [inscrit, labels,secretaire,titre]); // Assurez-vous d'inclure labels dans les dépendances de useEffect



 // DEBUT MISE A JOUR TAIILE DE L'ECRAN
  const [largeurEcran, setLargeurEcran] = useState(window.innerWidth);

  const mettreAJourLargeurEcran = () => {
      setLargeurEcran(window.innerWidth);
  
    };

  
    useEffect(() => {
        
      window.addEventListener('resize', mettreAJourLargeurEcran);
      return () => {
        window.removeEventListener('resize', mettreAJourLargeurEcran);
        
      };
     
    }, []);
  
// END


    const [minStartDate, setMinStartDate] = useState('');
    const [maxStartDate, setMaxStartDate] = useState('');
    const [minEndDate, setMinEndDate] = useState('');
    const [maxEndDate, setMaxEndDate] = useState('');
  
  
  
  
  // CODE POUR CONTROLER LE NOMBRE D'INTERVLLE DE MOIS POUR LES FILTRES
    const calculateMaxEndDate = (startDate, monthInterval) => {
      const start = new Date(startDate);
      const maxDate = new Date(start.setMonth(start.getMonth() + monthInterval));
      return maxDate.toISOString().split('T')[0];
    };
  
    const calculateMinEndDate = (startDate, monthInterval) => {
      const start = new Date(startDate);
      const minDate = new Date(start.setMonth(start.getMonth() + monthInterval));
      return minDate.toISOString().split('T')[0];
    };
  
    const calculateMaxStartDate = (endDate, monthInterval) => {
      const end = new Date(endDate);
      const maxDate = new Date(end.setMonth(end.getMonth() - monthInterval));
      return maxDate.toISOString().split('T')[0];
    };
  
    const calculateMinStartDate = (endDate, monthInterval) => {
      const end = new Date(endDate);
      const minDate = new Date(end.setMonth(end.getMonth() - monthInterval));
      return minDate.toISOString().split('T')[0];
    };
  
  
    const handleStartDateChange = (e) => {
      const selectedStartDate = e.target.value;
      setDate1(selectedStartDate);
  
      // Limiter la date de fin à un intervalle de 1 à 4 mois après la date de début
      const minDate = calculateMinEndDate(selectedStartDate, 1); // +1 mois
      const maxDate = calculateMaxEndDate(selectedStartDate, 4); // +4 mois
  
      setMinEndDate(minDate);
      setMaxEndDate(maxDate);
  
      // Réinitialiser la date de fin si elle n'est pas dans l'intervalle
      if (date2 && (date2 < minDate || date2 > maxDate)) {
        setDate2('');
      }
    };
  
  
  
    const handleEndDateChange = (e) => {
      const selectedEndDate = e.target.value;
      setDate2(selectedEndDate);
  
      // Limiter la date de début à un intervalle de 1 à 4 mois avant la date de fin
      const minDate = calculateMinStartDate(selectedEndDate, 4); // -4 mois
      const maxDate = calculateMaxStartDate(selectedEndDate, 1); // -1 mois
  
      setMinStartDate(minDate);
      setMaxStartDate(maxDate);
  
      // Réinitialiser la date de début si elle n'est pas dans l'intervalle
      if (date1 && (date1 < minDate || date1 > maxDate)) {
        setDate1('');
      }
    };
  
  
  
  let lig
  let input1
  let input2
  
    if(largeurEcran>= 1200){
  lig=<br/>
  input1=  <input type='date' value={date1} className='oixi' onChange={e => setDate1(e.target.value)}></input> 
  input2= <input type='date' value={date2}  className='oixi' onChange={e => setDate2(e.target.value)} ></input>
    }else{
      lig=''
      input1 = 
          <input type='date' value={date1} className='oixi' onChange={handleStartDateChange}  max={new Date().toISOString().split('T')[0]} ></input>
      input2=    <input type='date' value={date2}  className='oixi' onChange={handleEndDateChange}    min={minEndDate || ''} max={maxEndDate} disabled={!date1 && !date2}></input> 
      
      
      
    }

    //pour afficher les infos stats


    
    let load

    if(viewLoader==true){
        load=   <div className="avt">
        <div className="rin">
          <div className="chm">
            <Loader/>
          </div>
        </div>
      </div>
    }else{
        load=''
    }

    

    const[affInscrit, setAffInscrit]= useState('')
              
    
    const[ins, setIns]= useState(false) 
    
    const toggleInscrit = ()=> {
    

      setIns(!ins)
      setAffInscrit('')  
     
    }
    
    
    if(affInscrit!== ''){
     toggleInscrit()
     
    } 

    
    const[affAdm, setAffAdm]= useState('')
              
    
    const[adm, setAdm]= useState(false) 
    
    const toggleAdm = ()=> {
 
    setAdm(!adm)
    setAffAdm('')
   
    }
    
    
    if(affAdm!== ''){
     toggleAdm()
     
    } 

    const[affNdm, setAffNdm]= useState('')
    const[ndm, setNdm]= useState(false) 
    
    const toggleNdm = ()=> {  
    setNdm(!ndm)
    setAffNdm('')
    }
    
    if(affNdm!== ''){
     toggleNdm()
     
    } 
    
    const[affLesdep, setAffLesdep]= useState('') 
    const[lesDep, setLesdep]= useState(false) 
    
    const toggleLesdep = ()=> {
    setLesdep(!lesDep)
    setAffLesdep('')  
     
    }
    
    if(affLesdep!== ''){
     toggleLesdep()
     
    } 
    const[affLesPay, setAffLesPay]= useState('')
    const[lesPay, setLesPay]= useState(false) 
    
    const toggleLesPay = ()=> {
    setLesPay(!lesPay)
    setAffLesPay('')   
    }
    
   
    if(affLesPay!== ''){
     toggleLesPay() 
    } 

    const[affLesRt, setAffLesRt]= useState('')
    const[lesRt, setLesRt]= useState(false) 
    
    const toggleLesRt = ()=> {
    setLesRt(!lesRt)
      setAffLesRt('')     
    }
      
    if(affLesRt!== ''){
     toggleLesRt()
     
    } 

    //fin info stats

      Cookies.set('olaf', id, { expires: 7 })





      const [abonne, setAbonne] = useState(null);
      const [message, setMessage] = useState('');
    
    
      useEffect(() => {
        axios.post('https://gestauto-56bfcbe4b876.herokuapp.com/abonne', { id }, { headers })
            .then(res => {
                const expirationDate = res.data[0].expiration_date;
                setAbonne(expirationDate);
    
                if (expirationDate) {
                    const currentDate = moment();
                    const endDate = moment(expirationDate);
                    const daysLeft = endDate.diff(currentDate, 'days');
    
                    // Vérifiez si l'abonnement expirera dans les 7 jours
                    if (daysLeft <= 7 && daysLeft > 0) {
                        setMessage(`Fin d'abonnement dans ${daysLeft} jour(s).`);
                    }else if(daysLeft == 0){
                      setMessage(`Fin d'abonnement aujourd'hui.`);
                    }
                } else {
                    setMessage("");
                }
            })
            .catch(err => console.log(err));
    }, [id]); 







    if(error){
      return (<div>Une erreur est survenue : {error.message}</div>)
  }else{

  return (
    <>
     

  
    <div className='wax'>
    <Navbar/>

   {/*  <div className='ban2'>
    
    <div className="llo">AUTO ECOLE {lig}{secretaire.map((data)=>(data.auto_ecole))}</div>
   
    </div> */}

    
<div className='ban2'>
    
    <div >
      
     <span className="llo"> AUTO ECOLE {lig}{secretaire.map((data)=>(data.auto_ecole))}</span>

    {message && <div className="alertMM">{message}</div>} {/* Afficher le message d'alerte */}
    </div>

    </div>
    
   
    <div className='fil'>
      <div className='disEnd'>
        {input1} {input2}
            <button type='submit' onClick={handleSubmit} className='butonSF3D'>Appliquer</button>
           </div>
           </div>

           {load}

           <div className="dashboard-container">
           <Link className='bnfs' to={  secretaire.map((data)=>(data.poste))=="secretaire" ? '/candidat' : '/candidatMoni'}> 
              <div className="dashboard-card statistiques-generales">
                <div className="card-title">INSCRITS</div>
                <div className="card-content">
                  {inscritTotal}
                </div>
              </div>
              </Link>
              <Link className='bnfs' to={secretaire.map((data)=>(data.poste))=="secretaire" ?  '/candidatesPagesOui' : null}>
              <div className="dashboard-card statistiques-candidats" >
                <div className="card-title">ADMIS</div>
                <div className="card-content">
                {nbrAd}
                </div>
              </div>
              </Link>
              <Link className='bnfs'   to={secretaire.map((data)=>(data.poste))=="secretaire" ?  '/candidatesPages' : null}  >
              <div className="dashboard-card statistiques-annexes" >
                <div className="card-title">NON ADMIS</div>
                <div className="card-content">
            {nbrNad}
                </div>
              </div>
              </Link>
   

              {((secretaire.some(data => data.view_dep === 1) && titre === "moniteur") || (secretaire.some(data => data.manage_registrations === 1) && titre === "secretaire")) && (
              <Link className='bnfs' to={ secretaire.map((data)=>(data.poste))=="secretaire" ?  '/depense' : null}>
              <div className="dashboard-card alertes-notifications" >
                <div className="card-title">DEPENSES</div>
                <div className="card-content">
                  {depenseTotal} FCFA
                </div>
              </div>
              </Link>
  )}


{((secretaire.some(data => data.view_info === 1) && titre === "moniteur") || (secretaire.some(data => data.manage_payments === 1) && titre === "secretaire")) && (
              <Link className='bnfs' to={secretaire.map((data)=>(data.poste))=="secretaire" ? '/payement' : null}>
              <div className="dashboard-card resume-activites-recentes" >
                <div className="card-title">RECETTES</div>
                <div className="card-content">
                {paiementTotal} FCFA
                </div>
              </div>
              </Link>

  )}

              <Link className='bnfs' /* to={'/permis'} */>
              <div className="dashboard-card resume-activites-suv" >
                <div className="card-title">PERMIS RETIRÉ(S)</div>
                <div className="card-content">
               {permis}
                </div>
              </div>
              </Link>
              <br/>
            </div>
            <br/>  <br/>  <br/>
            
{ins && ( <>
  <div className="overlay"></div>
  <div className="fenetre-modal">
    <div className="modal-entete">
      <h3 className="titre-modal">Liste des Inscrits</h3>
    </div>
    <div className="modal-corps">
      <table className="table-inscrits">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
          </tr>
        </thead>
        <tbody>
          {lesI.reverse().map((data, i) => (
            <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
              <td>{data.nom}</td>
              <td>{data.prenom}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="boutons-modal">
      <Link onClick={toggleInscrit}>
        <button className="bouton-annuler">QUITTER</button>
      </Link>
    </div>
  </div>
</>)}


{adm && ( <>
  <div className="overlay"></div>
  <div className="fenetre-modal">
    <div className="modal-entete">
      <h3 className="titre-modal">Liste des admis</h3>
    </div>
    <div className="modal-corps">
      <table className="table-inscrits">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
          </tr>
        </thead>
        <tbody>
          {lesAdm.reverse().map((data, i) => (
            <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
              <td>{data.nom}</td>
              <td>{data.prenom}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="boutons-modal">
      <Link onClick={toggleAdm}>
        <button className="bouton-annuler">QUITTER</button>
      </Link>
    </div>
  </div>
</>)}




{lesRt && ( <>
  <div className="overlay"></div>
  <div className="fenetre-modal">
    <div className="modal-entete">
      <h3 className="titre-modal">Liste de ceux qui ont retirés leurs permis</h3>
    </div>
    <div className="modal-corps">
      <table className="table-inscrits">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
          </tr>
        </thead>
        <tbody>
          {listeRt.reverse().map((data, i) => (
            <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
              <td>{data.nom}</td>
              <td>{data.prenom}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="boutons-modal">
      <Link onClick={toggleLesRt}>
        <button className="bouton-annuler">QUITTER</button>
      </Link>
    </div>
  </div>
</>)}



{ndm && ( <>
  <div className="overlay"></div>
  <div className="fenetre-modal">
    <div className="modal-entete">
      <h3 className="titre-modal">Liste des non admis</h3>
    </div>
    <div className="modal-corps">
      <table className="table-inscrits">
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
          </tr>
        </thead>
        <tbody>
          {lesNdm.reverse().map((data, i) => (
            <tr key={i} className={i % 2 === 0 ? "ligne-grise" : "ligne-blanche"}>
              <td>{data.nom}</td>
              <td>{data.prenom}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div className="boutons-modal">
      <Link onClick={toggleNdm}>
        <button className="bouton-annuler">QUITTER</button>
      </Link>
    </div>
  </div>
</>)}


{lesDep && ( <>
  <div className="overlay"></div>
  <div className="fenetre-modal">
    <div className="modal-entete">
      <h3 className="titre-modal">Liste des dépenses</h3>
    </div>
    <div className="modal-corps">


    {
                      
                      listeDep.reverse().map((data, i)=> (
                          <div className="bilan-depense">
                              <span className='texte-depense'>
                          <span className="montant">{data.montant} FCFA</span><span className='txt'>
                          pour
                          </span>
                           <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                           </span>
                     
                           
                        </div>
                      ))
                    }

    </div>
    <div className="boutons-modal">
      <Link onClick={toggleLesdep}>
        <button className="bouton-annuler">QUITTER</button>
      </Link>
    </div>
  </div>
</>)}




{lesPay && ( <>
  <div className="overlay"></div>
  <div className="fenetre-modal">
    <div className="modal-entete">
      <h3 className="titre-modal">Liste des recettes</h3>
    </div>
    <div className="modal-corps">


    {
                      
                      listePay.reverse().map((data, i)=> (
                        <div className="bilan-depense">
                            
                        <span className='texte-depense'>
                        <div> {data.nom} {data.prenom}</div>
                    <span className="montant">{data.montant} FCFA</span><span className='txt'>
                    pour
                    </span>
                     <span className="libelle">{data.libelle}</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                     </span>
      
                     
                  </div>
                      ))
                    }
                     {
                      
                      listePay2.reverse().map((data, i)=> (
                        <div className="bilan-depense">
                            
                        <span className='texte-depense'>
                        <div> {data.nom} {data.prenom}</div>
                    <span className="montant">{data.montant_payer} FCFA</span><span className='txt'>
                    pour
                    </span>
                     <span className="libelle">Frias de formation</span><span className='txt'> ce</span>  <span className="date">{moment(data.date).utc(1).format('dddd DD MMMM YYYY')}</span>.
                     </span>
      
                     
                  </div>
                      ))
                    }

    </div>
    <div className="boutons-modal">
      <Link onClick={toggleLesPay}>
        <button className="bouton-annuler">QUITTER</button>
      </Link>
    </div>
  </div>
</>)}



{((secretaire.some(data => data.view_info === 1) && titre === "moniteur") ||  ((secretaire.some(data => data.manage_payments === 1) && 
    secretaire.some(data => data.manage_registrations === 1)) && titre === "secretaire")) && (
    <div className='graph'>
            <div className="cardDash">
          <h3 className='vost1'>Évolution Dynamique des Inscriptions de Candidats</h3>
          <div className="canvas-container">
            <canvas ref={chartRef}></canvas>
          </div>
        </div>
    
        <div className="cardDash">
          <h3 className='vost2'>Rapport financier</h3>
          <div className="canvas-container">
            <canvas ref={chartRef2}></canvas>
          </div>
        </div>
    
     </div>
  )}
         
     
            </div>
            <Footer/>
            </>
  );
}
};