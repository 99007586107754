import React,{ useEffect, useState} from 'react';
import axios from 'axios';
import { useNavigate,useParams } from "react-router-dom";
import Loader from "../components/loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
export default function UpResultCode(){
    const headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': 'https://gestauto.seg.solutions',
        'credentials': 'include',
      };
    const [name, setName]= useState('')
    const [prenom, setPrenom]= useState('')
    const {id}= useParams();
    const [loader, setLoader]= useState(false);
    const [loader2, setLoader2]= useState(true);
    const [result, setResult]= useState('')
    const [info, setInfo]= useState('')
    const [examDate,setExamDate]= useState('')
    var date= new Date();
  var autre= date.toISOString().split('T')[0] 
    
    const navigate = useNavigate();

    function handleSubmit(event){
        event.preventDefault();
        setLoader(true)
        if(  result==null){
            setLoader(false)
            setInfo("UN CHAMP N'EST PAS REMPLI VEILLEZ VERIFIER!!!")
           
         }else if (result<0 || result>20){
            setLoader(false)
            setInfo('VEILLEZ VERIFIE LA VALEUR DE LA NOTE')
         }else if(examDate=== null){
            setLoader(false)
            setInfo("Ce candidat n'a pas de date d'examen")
         }
         else{
        axios.put('https://gestauto-56bfcbe4b876.herokuapp.com/upResult/'+id, {name,prenom,result,autre}, { headers})
        .then(res => {
            setLoader(false)
            navigate('/secretaireResultCode');
        }).catch(err => console.log(err));
    }
    }

    
    const handleGoBack = () => {
        navigate('/secretaireResultCode');
      };

    const [candidat, setCandidat] = useState([])
    useEffect(()=>{
        axios.get('https://gestauto-56bfcbe4b876.herokuapp.com/update/'+id, { headers})
        .then(res => { 
            setResult(res.data[0].resultat_code)
            setExamDate(res.data[0].examen_date)
            setCandidat(res.data)})
        .catch(err => console.log(err));
    }, [])


    let load

    if(loader==true){
        load=   <div className="modal">
            <div className="overlay">

            </div>
               <div className="modal-contentis">
    <div className="anous">
    <Loader/>
    </div>
    </div>
        </div>
       
    }else{
        load=''
    }

    return(
        <>
        
        
        <div className="auths">
        
         
<body className="body">



<div className="container">
<FontAwesomeIcon icon={faArrowLeft} onClick={handleGoBack} style={{ color: 'white', fontSize: '28px', cursor: 'pointer' }} />
   <h1 className="form-title">Note Code</h1>
        <form action="#" onSubmit={handleSubmit}>
          <div className="main-user-info">


          <div className="user-input-box">
          <label htmlFor="nom" className="">Nom</label>
           <input type="text" name="nom" className="upo" id="nom" value={candidat.map((data)=>(data.nom))} onChange={e => setName(e.target.value)}></input>
           
          </div>

          <div className="user-input-box">
          <label htmlFor="prenom" className="">Prenom</label>
         
           <input type="text"  name="prenom" className="upo"  id="prenom"  value={candidat.map((data)=>(data.prenom))} onChange={e => setPrenom(e.target.value)} ></input>
           
          </div>

          <div className="libe">
          <label htmlFor="libelle" className="">Note</label>
           <input type="number" name="note" className="" id="note" maxLength={100} value={result} onChange={e => setResult(e.target.value)}></input>
           
          </div>
           
          </div>
           
           <h4 className="text-danger">{info}</h4>
           <div className="form-submit-btn">
           <input type="submit" className='' value="ENREGISTRER" ></input>
           </div>
        </form>
   </div> 
    <br/><br/><br/>
    </body>
    {load}
</div>

        
        
        </>
    )
}
