import React from 'react';


const Modal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2>Avertissement</h2>
        <p>Assurez-vous que la taille de votre image fait moins de 2 Mo.</p>
        <div className='continuer'>Continuer ?</div>
        <div className="popup-buttons">
        <button className='pop-non' onClick={onClose}>Non</button>
          <button className= 'pop-oui' onClick={onConfirm}>Oui</button>
          
        </div>
      </div>
    </div>
  );
};

export default Modal;
